import styled from 'styled-components';

const StyledContainer = styled.span<{ $centered: boolean }>`
  display: inline-block;

  ${(p) =>
    p.$centered &&
    `
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export function Spinner({ centered = false }: { centered?: boolean }) {
  // TODO: improve appearance
  return <StyledContainer $centered={centered}>...</StyledContainer>;
}
