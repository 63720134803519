import { ShoppingListData, UpdateShoppingListRequest } from 'common/apiTypes';
import { useEffect, useState } from 'react';
import { optimisticShoppingListUpdate, useShoppingListClient } from 'src/api/shoppingLists';
import { logError } from 'src/components/logging';

export function useShoppingList(shoppingListId: string) {
  const { getShoppingList, postUpdateShoppingList } = useShoppingListClient();

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState<ShoppingListData>();
  const [error, setError] = useState('');

  useEffect(() => {
    const doFetch = async () => {
      try {
        setIsLoading(true);
        setList(await getShoppingList(shoppingListId));
      } catch (err) {
        logError(err);
        setError('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };
    void doFetch();
  }, [shoppingListId, getShoppingList]);

  const updateShoppingList = (request: Omit<UpdateShoppingListRequest, 'shoppingListId'>) => {
    const doUpdate = async () => {
      try {
        setIsLoading(true);

        if (list) {
          setList(optimisticShoppingListUpdate(list, request));
        }

        // TODO: prevent race conditions between multiple updates
        setList(await postUpdateShoppingList({ ...request, shoppingListId }));
      } catch (err) {
        logError(err);
        setError('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };
    void doUpdate();
  };

  return {
    isLoading,
    list,
    error,
    updateShoppingList,
  };
}
