import { useEffect, useMemo, useState } from 'react';
import { useAutocompleteClient } from 'src/api/autocomplete';
import { logError } from 'src/components/logging';

export function useCategoryOptions(currentCategories: string[], isUserVisible: boolean) {
  const { getCategorySuggestions } = useAutocompleteClient();

  const [isLoading, setIsLoading] = useState(true);
  const [userCategories, setUserCategories] = useState<string[]>([]);

  useEffect(() => {
    // TODO: refresh stale data after some TTL
    if (!isLoading) {
      return;
    }

    void (async () => {
      try {
        const results = await getCategorySuggestions();

        setUserCategories(results);
        setIsLoading(false);
      } catch (err) {
        logError(err);
      }
    })();
  }, [getCategorySuggestions, isUserVisible, isLoading]);

  return useMemo(() => {
    const currentCategoriesLower = currentCategories.map((category) =>
      category.toLocaleLowerCase()
    );
    return [
      ...currentCategories,
      ...userCategories.filter(
        (category) => !currentCategoriesLower.includes(category.toLocaleLowerCase())
      ),
    ];
  }, [currentCategories, userCategories]);
}
