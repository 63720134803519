import { createContext, useContext, useEffect, useRef, useState } from 'react';

const CursorContext = createContext<[number, number]>([0, 0]);

export function CursorContextProvider({
  onChange = () => undefined,
  children,
}: {
  onChange?: (position: [number, number]) => void;
  children: React.ReactNode;
}) {
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    const handleMouseMove = (e: PointerEvent) => {
      setCursorX(e.clientX);
      setCursorY(e.clientY);
      onChangeRef.current([e.clientX, e.clientY]);
    };

    document.addEventListener('pointermove', handleMouseMove);
    document.addEventListener('pointerdown', handleMouseMove);

    return function cleanup() {
      document.removeEventListener('pointermove', handleMouseMove);
      document.removeEventListener('pointerdown', handleMouseMove);
    };
  }, []);

  return <CursorContext.Provider value={[cursorX, cursorY]}>{children}</CursorContext.Provider>;
}

export function useCursorClientPosition() {
  return useContext(CursorContext);
}
