import styled from 'styled-components';
import { NakedButton, Text } from './common';

const StyledButton = styled(NakedButton)`
  touch-action: none;
  cursor: move !important;
`;

export function DragButton({
  className,
  disabled,
  onDragStart,
  dragContainerRef,
}: {
  className?: string;
  disabled?: boolean;
  onDragStart: () => void;
  dragContainerRef?: React.RefObject<HTMLElement>;
}) {
  const handlePointerDown = (e: React.PointerEvent<HTMLButtonElement>) => {
    if (e.button !== 0 || !dragContainerRef?.current) {
      return;
    }
    dragContainerRef?.current.setPointerCapture(e.pointerId);

    onDragStart();
  };

  return (
    <StyledButton
      className={className}
      onPointerDown={handlePointerDown}
      onContextMenu={(e) => e.preventDefault()}
      disabled={disabled}
    >
      <Text $size="lg">&#8645;</Text>
    </StyledButton>
  );
}
