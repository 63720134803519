import styled from 'styled-components';
import { useRecipe } from './hooks/useRecipe';
import { AppHelmet } from 'src/components/AppHelmet';
import { PageHeading } from 'src/components/PageHeading';
import { ErrorText, HeadingText, Spacer, Text } from 'src/components/common';
import { Spinner } from 'src/components/Spinner';
import { Link } from 'src/components/Router';

const PageContainer = styled.div`
  width: 500px;
  max-width: 100%;
  margin: auto;
  overflow: visible;
  text-align: left;
`;

const StyledUl = styled.ul`
  margin-top: 8px;
  margin-left: 1em;
  line-height: 140%;

  & li {
    margin: 4px 0;
  }
`;

const StyledOl = styled.ol`
  margin-left: 1em;
  line-height: 140%;

  & li {
    margin: 12px 0;
  }
`;

export function ViewRecipePage({ recipeId }: { recipeId: string }) {
  const { recipe, error } = useRecipe(recipeId);

  if (error) {
    return (
      <>
        <AppHelmet context="Error" />
        <PageHeading backButton />
        <Spacer $flex>
          <ErrorText>{error}</ErrorText>
        </Spacer>
      </>
    );
  }

  if (!recipe) {
    return (
      <>
        <AppHelmet context="Loading" />
        <PageHeading backButton />
        <Spinner centered />
      </>
    );
  }

  const recipeNameOrDefault = recipe.name || 'Untitled recipe';

  return (
    <>
      <AppHelmet context={recipeNameOrDefault} />
      <PageHeading backButton>
        <HeadingText>
          {recipeNameOrDefault}
          <br />
          <Link href={`/recipe/${recipeId}/edit`} replaceHistory>
            Edit
          </Link>
        </HeadingText>
      </PageHeading>
      <PageContainer>
        {error && (
          <Spacer $mv="12px">
            <ErrorText>{error}</ErrorText>
          </Spacer>
        )}
        <Spacer $mb="32px">
          <Text $size="lg">Ingredients</Text>
          <StyledUl>
            {recipe.ingredients.map((ingredient, i) => (
              <li key={i}>{ingredient}</li>
            ))}
          </StyledUl>
        </Spacer>
        <Spacer $mb="32px">
          <Text $size="lg">Steps</Text>
          <StyledOl>
            {recipe.steps.map((step, i) => (
              <li key={i}>{step}</li>
            ))}
          </StyledOl>
        </Spacer>
      </PageContainer>
    </>
  );
}
