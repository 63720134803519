import styled from 'styled-components';

const StyledImg = styled.img`
  display: inline-block;
  margin: auto;
  width: 64px;
  height: auto;
`;

export function AppIcon() {
  return <StyledImg src="/icons/128.png" />;
}
