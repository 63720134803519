import React from 'react';
import ReactDOM from 'react-dom/client';
import { ShoppingListPage } from './pages/ShoppingListPage/ShoppingListPage';
import { HelmetProvider } from 'react-helmet-async';
import { ShoppingListsPage } from './pages/ShoppingListsPage/ShoppingListsPage';
import { PathParams, Route } from './components/Router';
import { SessionProvider } from './hooks/useSession';
import { JoinGroupPage } from './pages/JoinGroupPage/JoinGroupPage';
import { AuthRouter } from './pages/AuthRouter';
import { DeleteAccountPage } from './pages/DeleteAccountPage/DeleteAccountPage';
import * as Sentry from '@sentry/react';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { PublicHomePage } from './pages/PublicHomePage/PublicHomePage';
import { RecipesPage } from './pages/RecipesPage/RecipesPage';
import { ViewRecipePage } from './pages/RecipePage/ViewRecipePage';
import { EditRecipePage } from './pages/RecipePage/EditRecipePage';

const loggedInRoutes: Route[] = [
  ['/', () => <ShoppingListsPage />],
  ['/archive', () => <ShoppingListsPage viewMode="archive" />],
  ['/trash', () => <ShoppingListsPage viewMode="trash" />],
  [
    '/list/:listId',
    (pathParams: PathParams) => <ShoppingListPage listId={pathParams.get('listId')} />,
  ],
  ['/recipes/', () => <RecipesPage />],
  ['/recipes/trash', () => <RecipesPage viewMode="trash" />],
  [
    '/recipe/:recipeId',
    (pathParams: PathParams) => <ViewRecipePage recipeId={pathParams.get('recipeId')} />,
  ],
  [
    '/recipe/:recipeId/edit',
    (pathParams: PathParams) => <EditRecipePage recipeId={pathParams.get('recipeId')} />,
  ],
  [
    '/join/group/:inviteToken',
    (pathParams: PathParams) => <JoinGroupPage inviteToken={pathParams.get('inviteToken')} />,
  ],
  ['/delete-account', () => <DeleteAccountPage />],
  ['/login', () => <LoginPage />],
];

const loggedOutRoutes: Route[] = [
  ['/', () => <PublicHomePage />],
  ...loggedInRoutes.map(([path]): Route => [path, () => <LoginPage />]),
];

Sentry.init({
  dsn: 'https://4229d2bb1cb926bc73a108550de613ad@o4506799156887552.ingest.sentry.io/4506822820757504',
  integrations: [],
  environment: isProductionBuild ? 'production' : 'development',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <SessionProvider>
        <AuthRouter loggedOutRoutes={loggedOutRoutes} loggedInRoutes={loggedInRoutes} />
      </SessionProvider>
    </HelmetProvider>
  </React.StrictMode>
);
