import styled from 'styled-components';

const ScreenOverlay = styled.div<{ $dim?: boolean }>`
  position: fixed;
  z-index: 1999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  ${(p) => p.$dim && 'background-color: rgba(0, 0, 0, 0.125);'}
  overflow: auto;
  overscroll-behavior: contain;
`;

const ScrollContents = styled.div`
  width: 100%;
  min-height: calc(100% + 1px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalContent = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 24px;
  background: white;
  border-radius: 12px;
  box-shadow: -5px 5px 5px #bbb;
`;

export function Modal({
  isOpen,
  onCancel,
  children,
}: {
  isOpen: boolean;
  onCancel: () => void;
  children: React.ReactNode;
}) {
  if (!isOpen) {
    return <></>;
  }

  const handleClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onCancel();
    }
  };

  return (
    <ScreenOverlay $dim>
      <ScrollContents onClick={handleClick}>
        <ModalContent>{children}</ModalContent>
      </ScrollContents>
    </ScreenOverlay>
  );
}
