import { CategoryWithItems } from './useCategorizedItems';
import { useEffect, useState } from 'react';
import { DisplayItemUpdate } from './useShoppingListItems';

export function useItemSelectionState({
  categoriesAndItems,
  updateItems,
  deleteItems,
}: {
  categoriesAndItems: CategoryWithItems[];
  updateItems: (updates: DisplayItemUpdate[]) => void;
  deleteItems: (itemLocalIds: string[]) => void;
}) {
  const [selectedItemLocalIds, setSelectedItemLocalIds] = useState(new Set<string>());
  const [isEditingCategory, setIsEditingCategory] = useState(false);

  useEffect(() => {
    if (!selectedItemLocalIds.size) {
      setIsEditingCategory(false);
    }
  }, [selectedItemLocalIds]);

  useEffect(() => {
    const displayItems = categoriesAndItems.flatMap(
      ({ categoryDisplayItems }) => categoryDisplayItems
    );

    const invalidLocalIds: string[] = [];
    for (const itemLocalId of Array.from(selectedItemLocalIds.values())) {
      if (!displayItems.some((displayItem) => displayItem.localId === itemLocalId)) {
        invalidLocalIds.push(itemLocalId);
      }
    }

    if (invalidLocalIds.length) {
      setSelectedItemLocalIds((existing) => {
        const updated = new Set(existing);
        for (const invalidLocalId of invalidLocalIds) {
          updated.delete(invalidLocalId);
        }
        return updated;
      });
    }
  }, [selectedItemLocalIds, categoriesAndItems]);

  // TODO: support SHIFT+click to select multiple items
  const setIsSelected = (itemLocalId: string, checked: boolean) => {
    setSelectedItemLocalIds((existing) => {
      const updated = new Set(existing);
      if (checked) {
        updated.add(itemLocalId);
      } else {
        updated.delete(itemLocalId);
      }
      return updated;
    });
  };

  const handleClearSelection = () => {
    setSelectedItemLocalIds(new Set());
  };

  const handleChangeSelectionCategory = (category: string) => {
    const orderedSelectedItemLocalIds = [];
    for (const { categoryDisplayItems } of categoriesAndItems) {
      for (const displayItem of categoryDisplayItems) {
        if (selectedItemLocalIds.has(displayItem.localId)) {
          orderedSelectedItemLocalIds.push(displayItem.localId);
        }
      }
    }

    const categoryAndItems = categoriesAndItems.find((entry) => entry.category === category);
    let categoryItemCount = categoryAndItems?.categoryDisplayItems?.length || 0;

    const updates = [];
    for (const itemLocalId of orderedSelectedItemLocalIds) {
      updates.push({ itemLocalId, category, ordinal: categoryItemCount });
      categoryItemCount++;
    }

    updateItems(updates);

    setIsEditingCategory(false);
    handleClearSelection();
  };

  // TODO: feature to "undo" delete (or a delete confirmation)
  const handleDeleteSelection = () => {
    deleteItems(Array.from(selectedItemLocalIds.values()));

    handleClearSelection();
  };

  let commonSelectionCategory = undefined;
  if (selectedItemLocalIds.size) {
    for (const { category, categoryDisplayItems } of categoriesAndItems) {
      for (const displayItem of categoryDisplayItems) {
        if (!selectedItemLocalIds.has(displayItem.localId)) {
          continue;
        }

        if (commonSelectionCategory === undefined) {
          commonSelectionCategory = category;
        } else if (commonSelectionCategory !== category) {
          commonSelectionCategory = undefined;
          break;
        }
      }
    }
  }

  return {
    selectedItemLocalIds,
    setIsSelected,
    isEditingCategory,
    setIsEditingCategory,
    handleClearSelection,
    handleChangeSelectionCategory,
    handleDeleteSelection,
    commonSelectionCategory,
  };
}
