export class ListNode<T> {
  public prev: ListNode<T> | undefined;
  public next: ListNode<T> | undefined;

  constructor(public value: T) {}
}

export class LinkedList<T> {
  private head: ListNode<T> | undefined;
  private tail: ListNode<T> | undefined;

  peek(): T | undefined {
    return this.head?.value;
  }

  add(value: T): ListNode<T> {
    const node = new ListNode<T>(value);

    if (this.tail) {
      this.tail.next = node;
      node.prev = this.tail;
    } else {
      this.head = node;
    }

    this.tail = node;

    return node;
  }

  remove(node: ListNode<T>) {
    if (this.head === node) {
      this.head = node.next;
    }

    if (this.tail === node) {
      this.tail = node.prev;
    }

    if (node.prev) {
      node.prev.next = node.next;
    }

    if (node.next) {
      node.next.prev = node.prev;
    }
  }
}
