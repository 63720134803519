import { CurrentUser } from 'common/apiTypes';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getCurrentUser } from 'src/api/auth';
import { logError } from 'src/components/logging';

const SessionContext = createContext<{
  currentUser?: CurrentUser | undefined;
  sessionToken?: string | undefined;
  isLoading?: boolean;
  error?: string;
  onCreateSession: (sessionToken: string, currentUser: CurrentUser) => void;
  onRefreshCurrentUser: (sessionToken: string) => Promise<void>;
  onDestroySession: () => void;
}>({
  onCreateSession: () => undefined,
  onRefreshCurrentUser: () => Promise.resolve(),
  onDestroySession: () => undefined,
});

const SESSION_TOKEN_STORAGE_KEY = 'sessionToken';

function storeSessionToken(sessionToken: string) {
  localStorage.setItem(SESSION_TOKEN_STORAGE_KEY, sessionToken);
}

function clearSessionToken() {
  localStorage.removeItem(SESSION_TOKEN_STORAGE_KEY);
}

function loadSessionToken() {
  return localStorage.getItem(SESSION_TOKEN_STORAGE_KEY) ?? undefined;
}

export function useSession() {
  return useContext(SessionContext);
}

export function SessionProvider({ children }: { children: ReactNode }) {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [sessionToken, setSessionToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const handleCreateSession = (sessionToken: string, currentUser: CurrentUser) => {
    storeSessionToken(sessionToken);
    setSessionToken(sessionToken);
    setCurrentUser(currentUser);
  };

  const handleRefreshCurrentUser = async (sessionToken: string) => {
    const currentUser = await getCurrentUser(sessionToken);
    setCurrentUser(currentUser);
    setSessionToken(sessionToken);
  };

  const handleDestroySession = () => {
    clearSessionToken();
    setSessionToken(undefined);
    setCurrentUser(undefined);
  };

  useEffect(() => {
    const sessionToken = loadSessionToken();
    if (!sessionToken) {
      setIsLoading(false);
      return;
    }

    const doAsync = async () => {
      try {
        const currentUser = await getCurrentUser(sessionToken);
        setCurrentUser(currentUser);
        setSessionToken(sessionToken);
      } catch (err) {
        logError(err);
        setError('Something went wrong, please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    void doAsync();
  }, []);

  const contextValue = useMemo(
    () => ({
      currentUser,
      sessionToken,
      isLoading,
      error,
      onCreateSession: handleCreateSession,
      onRefreshCurrentUser: handleRefreshCurrentUser,
      onDestroySession: handleDestroySession,
    }),
    [currentUser, sessionToken, isLoading, error]
  );

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
}
