import { ShoppingListData, UpdateShoppingListRequest } from 'common/apiTypes';
import { forwardRef, useState } from 'react';
import { DragButton } from 'src/components/DragButton';
import { Spinner } from 'src/components/Spinner';
import { FlexChild, NakedButton, Text } from 'src/components/common';
import styled from 'styled-components';

const Card = styled.div<{ $isHidden?: boolean; $disabled?: boolean }>`
  width: 220px;
  height: 300px;
  padding: 24px 12px;
  background: white;
  border: 1px solid black;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;

  ${(p) =>
    p.$isHidden &&
    `
  & * {
    visibility: hidden;
  }
  border-style: dashed;
  border-color: blue;
  `}

  ${(p) =>
    p.$disabled &&
    `
  cursor: default;
  pointer-events: none;

  & input {
    cursor: auto;
    pointer-events: auto;
  }
  `}
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 38px;
`;

const ActionButton = styled(NakedButton)`
  padding: 8px 12px;

  &:hover {
    padding: 7px 11px;
    border: 1px solid gray;
  }
`;

const MS_PER_WEEK = 7 * 24 * 60 * 60 * 1000;
const MS_PER_DAY = 7 * 24 * 60 * 60 * 1000;

function getStartOfWeekMs(date = new Date()) {
  const startOfWeek = new Date(date.getTime());
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
  startOfWeek.setHours(0, 0, 0, 0);
  return startOfWeek.getTime();
}

function getStartOfDayMs(date = new Date()) {
  const startOfDay = new Date(date.getTime());
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay.getTime();
}

function ElapsedTime({ since }: { since: Date }) {
  const weekDeltaMs = getStartOfWeekMs() - getStartOfWeekMs(since);
  const deltaWeeks = Math.floor(weekDeltaMs / MS_PER_WEEK);

  const dayDeltaMs = getStartOfDayMs() - getStartOfDayMs(since);
  const deltaDays = Math.floor(dayDeltaMs / MS_PER_DAY);

  if (deltaDays <= 0) {
    return <>today</>;
  } else if (deltaDays == 1) {
    return <>yesterday</>;
  } else if (deltaWeeks <= 0) {
    return <>this week</>;
  } else if (deltaWeeks == 1) {
    return <>last week</>;
  } else {
    return <>{deltaWeeks} weeks ago</>;
  }
}

function ActionButtons({
  dragContainerRef,
  isArchived,
  isInTrash,
  onClickEdit,
  onClickArchive,
  onClickTrash,
  onClickRestore,
  onDragStart,
  disabled,
  isLoading,
}: {
  dragContainerRef: React.RefObject<HTMLElement>;
  isArchived: boolean;
  isInTrash: boolean;
  onClickEdit: () => void;
  onClickArchive: () => void;
  onClickTrash: () => void;
  onClickRestore: () => void;
  onDragStart: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}) {
  const handleClickEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClickEdit();
  };

  const handleClickArchive = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClickArchive();
  };

  const handleClickTrash = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClickTrash();
  };

  const handleClickRestore = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClickRestore();
  };

  if (isLoading) {
    return (
      <ActionButtonsContainer>
        <Spinner />
      </ActionButtonsContainer>
    );
  }

  if (isInTrash) {
    return (
      <ActionButtonsContainer>
        <button onClick={handleClickRestore} disabled={disabled}>
          Restore List
        </button>
      </ActionButtonsContainer>
    );
  }

  return (
    <ActionButtonsContainer>
      <DragButton dragContainerRef={dragContainerRef} onDragStart={onDragStart} />
      <ActionButton onClick={handleClickEdit} disabled={disabled}>
        &#9998;
      </ActionButton>
      {isArchived ? (
        <ActionButton onClick={handleClickRestore} disabled={disabled}>
          &#8617;
        </ActionButton>
      ) : (
        <ActionButton onClick={handleClickArchive} disabled={disabled}>
          &#128193;
        </ActionButton>
      )}
      <ActionButton onClick={handleClickTrash} disabled={disabled}>
        &#128465;
      </ActionButton>
    </ActionButtonsContainer>
  );
}

export const ShoppingListSummary = forwardRef(function ShoppingListSummary(
  {
    dragContainerRef,
    shoppingList,
    isLoading,
    isHidden,
    onClick,
    onDragStart,
    onUpdate,
    onDelete,
    onRestore,
  }: {
    dragContainerRef: React.RefObject<HTMLElement>;
    shoppingList: ShoppingListData;
    isLoading: boolean;
    isHidden: boolean;
    onClick: () => void;
    onDragStart: () => void;
    onUpdate: (request: UpdateShoppingListRequest) => void;
    onDelete: (shoppingListId: string) => void;
    onRestore: (shoppingListId: string) => void;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState('');

  const handleClickEdit = () => {
    setEditName(shoppingList.name);
    setIsEditing(true);
  };

  const handleClickArchive = () => {
    onUpdate({ shoppingListId: shoppingList.shoppingListId, isArchived: true });
  };

  const handleClickTrash = () => {
    onDelete(shoppingList.shoppingListId);
  };

  const handleClickRestore = () => {
    onRestore(shoppingList.shoppingListId);
  };

  const handleSubmitEdit = () => {
    setIsEditing(false);

    if (!editName) {
      return;
    }

    onUpdate({ shoppingListId: shoppingList.shoppingListId, name: editName });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      void handleSubmitEdit();
    }

    if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  return (
    <Card ref={ref} $isHidden={isHidden} $disabled={isLoading || isEditing} onClick={onClick}>
      <FlexChild $grow $shrink>
        {isEditing ? (
          <input
            type="text"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            onBlur={handleSubmitEdit}
            onKeyDown={handleKeyDown}
            onClick={(e) => e.stopPropagation()}
            autoFocus
          />
        ) : (
          <Text $size="lg">{shoppingList.name}</Text>
        )}
      </FlexChild>
      <FlexChild>
        <Text>
          {shoppingList.checkedItemCount} / {shoppingList.totalItemCount} items
        </Text>
      </FlexChild>
      <FlexChild>
        <Text $size="sm" $muted>
          Updated <ElapsedTime since={new Date(shoppingList.modifiedAtMs)} />
        </Text>
      </FlexChild>
      <FlexChild>
        <ActionButtons
          dragContainerRef={dragContainerRef}
          isArchived={shoppingList.isArchived}
          isInTrash={shoppingList.isInTrash}
          onClickEdit={handleClickEdit}
          onClickArchive={handleClickArchive}
          onClickTrash={handleClickTrash}
          onClickRestore={handleClickRestore}
          onDragStart={onDragStart}
          disabled={isEditing}
          isLoading={isLoading}
        />
      </FlexChild>
    </Card>
  );
});
