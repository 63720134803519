import { useRef } from 'react';
import { useCurrentRef } from 'src/hooks/useCurrentRef';
import { useSession } from 'src/hooks/useSession';
import { getAuthHeaders } from './auth';

export function useAccountClient() {
  const { sessionToken } = useSession();

  async function postDeleteAccount() {
    const response = await fetch('/api/deleteAccount', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    });

    return (await response.json()) as {};
  }

  const currentRef = useCurrentRef({
    postDeleteAccount,
  });

  const staticRef = useRef({
    postDeleteAccount: () => currentRef.current.postDeleteAccount(),
  });

  return staticRef.current;
}
