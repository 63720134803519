import { useState } from 'react';
import { logError } from 'src/components/logging';
import { PageHeading } from 'src/components/PageHeading';
import { ErrorText, HeadingText, Spacer, Text } from 'src/components/common';
import styled from 'styled-components';
import { useSession } from 'src/hooks/useSession';
import { Spinner } from 'src/components/Spinner';
import { useNavigate } from 'src/components/Router';
import { AppHelmet } from 'src/components/AppHelmet';
import { useAccountClient } from 'src/api/account';

const PageContainer = styled.div`
  max-width: 440px;
  margin: auto;
`;

export function DeleteAccountPage() {
  const { navigate } = useNavigate();
  const { currentUser, onDestroySession } = useSession();
  const { postDeleteAccount } = useAccountClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setSubmitError('');

      await postDeleteAccount();

      onDestroySession();
      navigate('/');
    } catch (err) {
      logError(err);
      setSubmitError('Something went wrong');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <AppHelmet context="Delete Account" />
      <PageHeading backButton>
        <HeadingText>Delete Account</HeadingText>
      </PageHeading>
      <PageContainer>
        <Text $centered>
          <strong>Warning:</strong> By proceeding, your account and all related information will be
          deleted. This action cannot be reversed.
        </Text>
        <Spacer $mt="24px">
          <Text $centered>You are currently signed in as {currentUser?.email}.</Text>
        </Spacer>
        <Spacer $mt="24px">
          {isSubmitting ? <Spinner /> : <button onClick={handleSubmit}>Delete My Account</button>}
        </Spacer>
        {submitError && (
          <Spacer $mt="12px">
            <ErrorText>{submitError}</ErrorText>
          </Spacer>
        )}
      </PageContainer>
    </>
  );
}
