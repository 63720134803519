import {
  AcceptGroupInviteRequest,
  CreateGroupInviteResponse,
  GroupInviteDetailsRequest,
  GroupInviteDetailsResponse,
} from 'common/apiTypes';
import { useRef } from 'react';
import { useCurrentRef } from 'src/hooks/useCurrentRef';
import { useSession } from 'src/hooks/useSession';
import { getAuthHeaders } from './auth';

export function useSharingClient() {
  const { sessionToken } = useSession();

  async function postCreateGroupInvite() {
    const response = await fetch('/api/createGroupInvite', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    });

    return (await response.json()) as CreateGroupInviteResponse;
  }

  async function getGroupInviteDetails(request: GroupInviteDetailsRequest) {
    const response = await fetch('/api/groupInviteDetails', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as GroupInviteDetailsResponse;
  }

  async function postAcceptGroupInvite(request: AcceptGroupInviteRequest) {
    const response = await fetch('/api/acceptGroupInvite', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as {};
  }

  const currentRef = useCurrentRef({
    postCreateGroupInvite,
    getGroupInviteDetails,
    postAcceptGroupInvite,
  });

  const staticRef = useRef({
    postCreateGroupInvite: () => currentRef.current.postCreateGroupInvite(),
    getGroupInviteDetails: (request: GroupInviteDetailsRequest) =>
      currentRef.current.getGroupInviteDetails(request),
    postAcceptGroupInvite: (request: AcceptGroupInviteRequest) =>
      currentRef.current.postAcceptGroupInvite(request),
  });

  return staticRef.current;
}
