import styled from 'styled-components';

export const Spacer = styled.div<{
  $m?: string | undefined;
  $mv?: string | undefined;
  $mh?: string | undefined;
  $mt?: string | undefined;
  $mb?: string | undefined;
  $ml?: string | undefined;
  $mr?: string | undefined;
  $p?: string | undefined;
  $pv?: string | undefined;
  $ph?: string | undefined;
  $pt?: string | undefined;
  $pb?: string | undefined;
  $flex?: boolean | undefined;
  $alignCenter?: boolean | undefined;
}>`
  margin: ${(p) => p.$m || `${p.$mv || '0'} ${p.$mh || '0'}`};
  ${(p) => p.$mt && `margin-top: ${p.$mt};`}
  ${(p) => p.$mb && `margin-bottom: ${p.$mb};`}
  ${(p) => p.$ml && `margin-left: ${p.$ml};`}
  ${(p) => p.$mr && `margin-right: ${p.$mr};`}
  padding: ${(p) => p.$p || `${p.$pv || '0'} ${p.$ph || '0'}`};
  ${(p) => p.$pt && `padding-top: ${p.$pt};`}
  ${(p) => p.$pb && `padding-bottom: ${p.$pb};`}
  ${(p) =>
    p.$flex &&
    `
  display: flex;
  justify-content: center;
  `}
  ${(p) => p.$alignCenter && 'align-items: center;'}
`;

export const FlexChild = styled(Spacer)<{ $shrink?: boolean; $grow?: boolean }>`
  max-width: 100%;
  ${(p) => (p.$shrink ? 'overflow: hidden;' : 'flex-shrink: 0;')}
  ${(p) => p.$grow && 'flex-grow: 1;'}
`;

export const Text = styled.p<{
  $size?: 'sm' | 'md' | 'lg' | 'xl';
  $centered?: boolean;
  $justify?: boolean;
  $muted?: boolean;
  $noWrap?: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(p) =>
    p.$size === 'sm' ? '12px' : p.$size === 'lg' ? '20px' : p.$size === 'xl' ? '28px' : '16px'};
  ${(p) => p.$centered && 'text-align: center;'}
  ${(p) => p.$justify && 'text-align: justify;'}
  ${(p) => p.$muted && 'color: gray;'}
  ${(p) => p.$noWrap && 'white-space: nowrap;'}

  & a {
    font-size: ${(p) =>
      p.$size === 'sm' ? '12px' : p.$size === 'lg' ? '20px' : p.$size === 'xl' ? '28px' : '16px'};
    ${(p) => p.$muted && 'color: gray;'}
  }
`;

export const HeadingText = styled.h1`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: normal;
  font-size: 26px;
`;

export const ErrorText = styled.p`
  color: red;
`;

export const NakedButton = styled.button`
  height: auto;
  border: 0;
  background: transparent;

  &:not([disabled]) {
    cursor: pointer;

    &:hover {
      color: gray;
    }
  }
`;

export const TextButton = styled(NakedButton)`
  text-decoration: underline;
  color: blue;
`;

export function CloseButton({
  onClick,
  size = 'md',
  className,
  disabled,
}: {
  onClick: () => void;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  disabled?: boolean;
}) {
  return (
    <NakedButton className={className} onClick={onClick} disabled={disabled}>
      <Text $size={size}>&#x2715;</Text>
    </NakedButton>
  );
}
