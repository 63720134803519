import {
  ShoppingListData,
  UpdateShoppingListOrderRequest,
  UpdateShoppingListRequest,
} from 'common/apiTypes';
import { useEffect, useState } from 'react';
import { optimisticShoppingListUpdate, useShoppingListClient } from 'src/api/shoppingLists';
import { logError } from 'src/components/logging';

export type ShoppingListState = { list: ShoppingListData; isLoading: boolean };

// TODO: remove this and share string union status across frontend
export function getListStatus({ list }: ShoppingListState) {
  if (list.isInTrash) {
    return 'trashed';
  }
  if (list.isArchived) {
    return 'archived';
  }
  if (list.isPinned) {
    return 'pinned';
  }
  return '';
}

function applyListIdOrder(
  states: ShoppingListState[],
  normalListIdOrder: string[],
  archiveListIdOrder: string[],
  dragListId: string,
  dragListToIndex: number
) {
  const stateById = new Map(states.map((state) => [state.list.shoppingListId, state]));

  const orderedIds = normalListIdOrder
    .filter((listId) => {
      const state = stateById.get(listId);
      return state && getListStatus(state) === '';
    })
    .concat(
      archiveListIdOrder.filter((listId) => {
        const state = stateById.get(listId);
        return state && getListStatus(state) === 'archived';
      })
    );

  const orderedStates: ShoppingListState[] = orderedIds
    .map((listId) => stateById.get(listId))
    .filter((state): state is ShoppingListState => !!state);

  const orderedIdSet = new Set(orderedIds);
  const remainingStates = states.filter(({ list }) => !orderedIdSet.has(list.shoppingListId));
  remainingStates.sort((a, b) => b.list.modifiedAtMs - a.list.modifiedAtMs);

  let orderedListStates = [...remainingStates, ...orderedStates];

  const dragListState = dragListId
    ? states.find(({ list }) => list.shoppingListId === dragListId)
    : undefined;
  if (dragListState) {
    orderedListStates = orderedListStates.filter((state) => state !== dragListState);

    // Drag to index is isolated by status
    let adjustedDragIndex = 0;
    let matchingCount = 0;
    while (matchingCount < dragListToIndex) {
      if (getListStatus(orderedListStates[adjustedDragIndex]) === getListStatus(dragListState)) {
        matchingCount++;
      }
      adjustedDragIndex++;
    }
    orderedListStates.splice(adjustedDragIndex, 0, dragListState);
  }

  return { orderedListStates, dragListState };
}

export function useShoppingLists() {
  const {
    getShoppingLists,
    postUpdateShoppingListOrder,
    postCreateShoppingList,
    postDeleteShoppingList,
    postRestoreShoppingList,
    postUpdateShoppingList,
  } = useShoppingListClient();

  const [isLoading, setIsLoading] = useState(true);
  const [shoppingListStates, setShoppingListStates] = useState<ShoppingListState[]>([]);
  const [normalListIdOrder, setNormalListIdOrder] = useState<string[]>([]);
  const [archiveListIdOrder, setArchiveListIdOrder] = useState<string[]>([]);
  const [dragListId, setDragListId] = useState('');
  const [dragListToIndex, setDragListToIndex] = useState(0);
  const [error, setError] = useState('');

  const updateShoppingListOrder = (request: UpdateShoppingListOrderRequest) => {
    if (request.normalListIdOrder) {
      setNormalListIdOrder(request.normalListIdOrder);
    }
    if (request.archiveListIdOrder) {
      setArchiveListIdOrder(request.archiveListIdOrder);
    }

    const doAsync = async () => {
      try {
        await postUpdateShoppingListOrder(request);
      } catch (err) {
        // The list order displayed on the frontend is now out of sync with the backend. Log error silently since this is
        // a superficial defect.
        logError(err);
      }
    };
    void doAsync();
  };

  useEffect(() => {
    const loadLists = async () => {
      try {
        setIsLoading(true);

        const { shoppingLists, normalListIdOrder, archiveListIdOrder } = await getShoppingLists();

        setShoppingListStates(shoppingLists.map((list) => ({ list, isLoading: false })));
        setNormalListIdOrder(normalListIdOrder);
        setArchiveListIdOrder(archiveListIdOrder);
      } catch (err) {
        logError(err);
        setError('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };
    void loadLists();
  }, [getShoppingLists]);

  const createShoppingList = async (name: string) => {
    const newList = await postCreateShoppingList({ name });

    setShoppingListStates((states) => [{ list: newList, isLoading: false }, ...states]);

    return newList;
  };

  const updateShoppingList = (request: UpdateShoppingListRequest) => {
    const doUpdate = async () => {
      setShoppingListStates((states) =>
        states.map((state) =>
          state.list.shoppingListId === request.shoppingListId
            ? { list: optimisticShoppingListUpdate(state.list, request), isLoading: true }
            : state
        )
      );

      const newList = await postUpdateShoppingList(request);

      setShoppingListStates((states) =>
        states.map((state) =>
          state.list.shoppingListId === newList.shoppingListId
            ? { list: newList, isLoading: false }
            : state
        )
      );
    };
    void doUpdate();
  };

  const deleteShoppingList = (shoppingListId: string) => {
    const doDelete = async () => {
      await postDeleteShoppingList({ shoppingListId });

      setShoppingListStates((states) =>
        states.map((state) =>
          state.list.shoppingListId === shoppingListId
            ? {
                ...state,
                list: { ...state.list, isArchived: false, isPinned: false, isInTrash: true },
              }
            : state
        )
      );
    };
    void doDelete();
  };

  const restoreShoppingList = (shoppingListId: string) => {
    const doRestore = async () => {
      const newList = await postRestoreShoppingList({ shoppingListId });

      setShoppingListStates((states) =>
        states.map((state) =>
          state.list.shoppingListId === newList.shoppingListId
            ? { list: newList, isLoading: false }
            : state
        )
      );
    };
    void doRestore();
  };

  const { orderedListStates, dragListState } = applyListIdOrder(
    shoppingListStates,
    normalListIdOrder,
    archiveListIdOrder,
    dragListId,
    dragListToIndex
  );

  return {
    isLoading,
    orderedListStates,
    error,
    dragListState,
    setDragListId,
    setDragListToIndex,
    createShoppingList,
    updateShoppingList,
    deleteShoppingList,
    restoreShoppingList,
    updateShoppingListOrder,
  };
}
