import { CategoryDisplayItem, CategoryWithItems } from './useCategorizedItems';
import { DisplayItemUpdate } from './useShoppingListItems';

export function useItemDragHandlers({
  itemElements,
  updateItems,
  categoriesAndItems,
  dragDisplayItem,
  setDragItemLocalId,
  setDragItemToCategory,
  setDragItemToIndex,
}: {
  itemElements: Map<CategoryDisplayItem, HTMLElement>;
  updateItems: (updates: DisplayItemUpdate[]) => void;
  categoriesAndItems: CategoryWithItems[];
  dragDisplayItem: CategoryDisplayItem | undefined;
  setDragItemLocalId: (itemId: string | undefined) => void;
  setDragItemToCategory: (category: string) => void;
  setDragItemToIndex: (index: number) => void;
}) {
  const handleDragStart = (category: string, displayItem: CategoryDisplayItem) => {
    setDragItemLocalId(displayItem.localId);
    setDragItemToCategory(category);
    setDragItemToIndex(displayItem.index);
  };

  const handleDragMove = ([, cursorY]: [number, number]) => {
    if (!dragDisplayItem) {
      return;
    }

    const clampedCursorY = Math.max(0, Math.min(window.innerHeight - 1, cursorY));

    let nearest;
    for (const [displayItem, el] of Array.from(itemElements.entries())) {
      const rect = el.getBoundingClientRect();

      // Exclude off-screen items
      if (displayItem !== dragDisplayItem && (rect.top < 0 || rect.bottom >= window.innerHeight)) {
        continue;
      }

      const distance = Math.abs(clampedCursorY - (rect.top + rect.bottom) / 2);
      if (!nearest || distance < nearest.distance) {
        nearest = { distance, displayItem, rect };
      }
    }

    if (nearest && nearest.displayItem !== dragDisplayItem) {
      let insertAfter = clampedCursorY > nearest.rect.bottom;
      const expectedTop = insertAfter ? nearest.rect.bottom : nearest.rect.top;
      const expectedBottom = expectedTop + nearest.rect.height;
      // Avoid inserting off-screen
      if (expectedTop <= 12) {
        insertAfter = true;
      }
      if (expectedBottom >= window.innerHeight - 12) {
        insertAfter = false;
      }

      setDragItemToCategory(nearest.displayItem.item.category);
      setDragItemToIndex(nearest.displayItem.index + (insertAfter ? 1 : 0));
    }
  };

  const handleDragEnd = () => {
    if (!dragDisplayItem) {
      return;
    }

    const updates = [];
    for (const { category, categoryDisplayItems } of categoriesAndItems) {
      for (const [index, displayItem] of Array.from(categoryDisplayItems.entries())) {
        const update: DisplayItemUpdate = {
          itemLocalId: displayItem.localId,
        };
        if (displayItem.item.ordinal !== index) {
          update.ordinal = index;
        }
        if (displayItem.item.category !== category) {
          update.category = category;
        }

        if (update.ordinal !== undefined || update.category !== undefined) {
          updates.push(update);
        }
      }
    }

    updateItems(updates);

    setDragItemLocalId(undefined);
  };

  return { handleDragStart, handleDragMove, handleDragEnd };
}
