import { HeadingText, Spacer, Text } from 'src/components/common';
import { PageHeading } from 'src/components/PageHeading';
import { LongAppHelmet, AppName, LongAppName } from 'src/components/AppHelmet';
import { AppIcon } from 'src/components/AppIcon';
import { Link } from 'src/components/Router';
import styled from 'styled-components';

const DescriptionContainer = styled.div`
  position: relative;
  max-width: 600px;
  margin: auto;
`;

const ScreenshotContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  padding: 24px 24px;

  & img {
    width: 20%;
    min-width: 200px;
    max-width: 300px;
    border: 1px solid black;
  }
`;

export function PublicHomePage() {
  return (
    <>
      <LongAppHelmet />
      <PageHeading breakoutContent>
        <HeadingText>
          <LongAppName />
        </HeadingText>
        <Spacer $pt="24px">
          <AppIcon />
        </Spacer>
      </PageHeading>
      <Spacer $ph="24px">
        <DescriptionContainer>
          <Text $size="md" $justify>
            <AppName /> is a free web app to compose and organize your shopping lists quickly with
            personalized suggestions and item categories.
            <br />
            <br />
            Share lists with friends and family using group sharing. Go shopping together and see
            real-time updates, including who completed each item.
          </Text>
        </DescriptionContainer>
      </Spacer>
      <Spacer $pv="48px">
        <Text $size="lg" $centered>
          <Link href="/login">Sign up or log in</Link> to get started!
        </Text>
      </Spacer>
      <ScreenshotContainer>
        <img src="/screenshots/1.png" alt="Screenshot of user interface on mobile" />
        <img src="/screenshots/2.png" alt="Screenshot of user interface on mobile" />
        <img src="/screenshots/3.png" alt="Screenshot of user interface on mobile" />
        <img src="/screenshots/4.png" alt="Screenshot of user interface on mobile" />
      </ScreenshotContainer>
    </>
  );
}
