import { useEffect, useRef, useState } from 'react';
import type {
  ShoppingListItemData,
  ShoppingListRequest,
  ShoppingListResponse,
  ShoppingListRequestAddItem,
  ShoppingListRequestUpdateItem,
  OtherUser,
} from 'common/apiTypes';
import { logError } from 'src/components/logging';
import { useCurrentRef } from 'src/hooks/useCurrentRef';
import { useSession } from 'src/hooks/useSession';

function getShoppingListWsUrl(sessionToken: string, listId: string) {
  const proto = window.location.protocol === 'https:' ? 'wss:' : 'ws:';

  // TODO: avoid putting session token in URL (temporary tickets?)
  return `${proto}//${window.location.host}/api/shoppingList/${listId}/${sessionToken}`;
}

export interface ShoppingListDisplayItem {
  localId: string;
  checkedByUser: OtherUser | undefined;
  item: Omit<ShoppingListItemData, 'shoppingListItemId'>;
}

export type DisplayItemUpdate = Omit<ShoppingListRequestUpdateItem, 'shoppingListItemId'> & {
  itemLocalId: string;
};

function applyResponse(
  currentItems: ShoppingListItemData[],
  response: ShoppingListResponse
): ShoppingListItemData[] {
  const itemMap = new Map(currentItems.map((item) => [item.shoppingListItemId, item]));

  for (const item of response.addedItems) {
    itemMap.set(item.shoppingListItemId, item);
  }

  for (const item of response.updatedItems) {
    itemMap.set(item.shoppingListItemId, item);
  }

  for (const itemId of response.deletedItemIds) {
    itemMap.delete(itemId);
  }

  return Array.from(itemMap.values());
}

function applyResponseToUserMap(
  currentUserMap: Record<string, OtherUser>,
  response: ShoppingListResponse
): Record<string, OtherUser> {
  const newUserMap = { ...currentUserMap };

  for (const user of response.includedUsers) {
    newUserMap[user.userId] = user;
  }

  return newUserMap;
}

const LOCAL_ID_PREFIX = 'localId-';

function getPendingLocalId(requestId: string, addItemIndex: number) {
  return `${LOCAL_ID_PREFIX}${requestId}-add-${addItemIndex}`;
}

function getShoppingListItemId(localId: string, localIdToItemId: Map<string, string>) {
  if (!localId.startsWith(LOCAL_ID_PREFIX)) {
    return localId;
  }

  return localIdToItemId.get(localId);
}

function getDisplayItems(
  currentItems: ShoppingListItemData[],
  userMap: Record<string, OtherUser>,
  pendingRequests: ShoppingListRequest[],
  queuedUpdates: DisplayItemUpdate[],
  queuedDeletedLocalIds: string[],
  itemIdToLocalId: Map<string, string>
): ShoppingListDisplayItem[] {
  const displayItemMap = new Map<string, ShoppingListDisplayItem>(
    currentItems.map((item) => [
      item.shoppingListItemId,
      {
        localId: itemIdToLocalId.get(item.shoppingListItemId) || item.shoppingListItemId,
        checkedByUser:
          item.isChecked && item.checkedByUserId ? userMap[item.checkedByUserId] : undefined,
        item,
      },
    ])
  );

  for (const request of pendingRequests) {
    for (const item of request.updatedItems) {
      const existingItem = displayItemMap.get(item.shoppingListItemId);
      if (!existingItem) {
        continue;
      }

      existingItem.item = { ...existingItem.item, ...item };
    }

    for (const itemId of request.deletedItemIds) {
      displayItemMap.delete(itemId);
    }
  }

  const existingDisplayItems = Array.from(displayItemMap.values());

  const addedDisplayItemByLocalId = new Map(
    pendingRequests
      .map((update) =>
        update.addedItems.map(
          (item, i): ShoppingListDisplayItem => ({
            localId: getPendingLocalId(update.requestId, i),
            checkedByUser: undefined,
            item,
          })
        )
      )
      .flat()
      .map((addedItem) => [addedItem.localId, addedItem])
  );

  // Queued updates/deletes only apply to pending added items, since the queue is flushed
  // as soon as the relevant pending additions complete.
  for (const { itemLocalId, ...updatedFields } of queuedUpdates) {
    const addedItem = addedDisplayItemByLocalId.get(itemLocalId);
    if (!addedItem) {
      continue;
    }

    addedItem.item = { ...addedItem.item, ...updatedFields };
  }

  for (const localId of queuedDeletedLocalIds) {
    addedDisplayItemByLocalId.delete(localId);
  }

  const addedDisplayItems = Array.from(addedDisplayItemByLocalId.values());

  return [...existingDisplayItems, ...addedDisplayItems];
}

export function useShoppingListItems(listId: string) {
  const { sessionToken } = useSession();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [items, setItems] = useState<ShoppingListItemData[]>([]);
  const [userMap, setUserMap] = useState<Record<string, OtherUser>>({});
  const [pendingRequests, setPendingRequests] = useState<ShoppingListRequest[]>([]);
  const [queuedUpdates, setQueuedUpdates] = useState<DisplayItemUpdate[]>([]);
  const [queuedDeletedLocalIds, setQueuedDeletedLocalIds] = useState<string[]>([]);
  const [error, setError] = useState('');

  const itemIdToLocalIdRef = useRef(new Map<string, string>());
  const localIdToItemIdRef = useRef(new Map<string, string>());

  const wsRef = useRef<WebSocket>();
  const nextRequestIdRef = useRef(0);

  const flushQueuedRequestRef = useCurrentRef(() => {
    if (queuedUpdates.length || queuedDeletedLocalIds.length) {
      setQueuedUpdates([]);
      setQueuedDeletedLocalIds([]);

      doRequest({
        addedItems: [],
        updatedItems: queuedUpdates,
        deletedItemLocalIds: queuedDeletedLocalIds,
      });
    }
  });

  useEffect(() => {
    const handleDisconnect = () => {
      setIsConnected(false);
      setError('');
      // TODO: de-couple pending updates from websocket connection (see TODO below)
      setPendingRequests([]);
      setQueuedUpdates([]);
      setQueuedDeletedLocalIds([]);
    };

    // TODO: throttle reconnects on failure, detect permanent errors
    const connectWebSocket = () => {
      const ws = new WebSocket(getShoppingListWsUrl(sessionToken || '', listId));
      wsRef.current = ws;

      ws.onclose = (event) => {
        handleDisconnect();

        // Custom status codes indicate intentional disconnect from the server
        if (event.code >= 4000) {
          logError(new Error(`websocket closed with code ${event.code}`));
          setError('Something went wrong');
        } else {
          connectWebSocket();
        }
      };

      ws.onmessage = (event) => {
        const response = JSON.parse(String(event.data)) as ShoppingListResponse;

        if (response.isReset) {
          setHasLoaded(true);
          setIsConnected(true);
          setItems([]);
        }

        setItems((currentItems) => applyResponse(currentItems, response));
        setUserMap((currentUserMap) => applyResponseToUserMap(currentUserMap, response));

        const requestId = response.requestId;
        if (requestId) {
          for (const [index, addedItem] of Array.from(response.addedItems.entries())) {
            const localId = getPendingLocalId(requestId, index);
            itemIdToLocalIdRef.current.set(addedItem.shoppingListItemId, localId);
            localIdToItemIdRef.current.set(localId, addedItem.shoppingListItemId);
          }

          setPendingRequests((currentPending) =>
            currentPending.filter((update) => update.requestId !== requestId)
          );

          flushQueuedRequestRef.current();
        }
      };
    };

    connectWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.onclose = null;
        wsRef.current.close();
        handleDisconnect();
      }
    };
  }, [sessionToken, listId, flushQueuedRequestRef]);

  const createRequestId = () => String(nextRequestIdRef.current++);

  const doRequest = ({
    addedItems,
    updatedItems,
    deletedItemLocalIds,
  }: {
    addedItems: ShoppingListRequestAddItem[];
    updatedItems: DisplayItemUpdate[];
    deletedItemLocalIds: string[];
  }) => {
    // Start building request with actual IDs in place of local IDs
    const request: ShoppingListRequest = {
      requestId: createRequestId(),
      addedItems,
      updatedItems: [],
      deletedItemIds: [],
    };

    // Some updates may affect pending items, which need to be queued for later
    const updatesToQueue: DisplayItemUpdate[] = [];
    for (const { itemLocalId, ...updatedFields } of updatedItems) {
      const shoppingListItemId = getShoppingListItemId(itemLocalId, localIdToItemIdRef.current);
      if (shoppingListItemId) {
        request.updatedItems.push({ ...updatedFields, shoppingListItemId });
      } else {
        updatesToQueue.push({ ...updatedFields, itemLocalId });
      }
    }

    if (updatesToQueue.length) {
      setQueuedUpdates((existing) => [...existing, ...updatesToQueue]);
    }

    // Some deletes may affect pending items, which need to be queued for later
    const deletedLocalIdsToQueue: string[] = [];
    for (const itemLocalId of deletedItemLocalIds) {
      const shoppingListItemId = getShoppingListItemId(itemLocalId, localIdToItemIdRef.current);
      if (shoppingListItemId) {
        request.deletedItemIds.push(shoppingListItemId);
      } else {
        deletedLocalIdsToQueue.push(itemLocalId);
      }
    }

    if (deletedLocalIdsToQueue) {
      setQueuedDeletedLocalIds((existing) => [...existing, ...deletedLocalIdsToQueue]);
    }

    if (
      !request.addedItems.length &&
      !request.updatedItems.length &&
      !request.deletedItemIds.length
    ) {
      return;
    }

    // TODO: send requests as regular API calls (out of band from websocket), handle request failure
    // with retry / removing individual pending updates
    // NOTE: if using separate requests, we must ensure updates are applied in-order
    // TODO: allow queued updates while disconnected, avoid disabling inputs
    if (wsRef.current?.readyState !== WebSocket.OPEN) {
      throw new Error('Not connected');
    }

    wsRef.current.send(JSON.stringify(request));

    setPendingRequests((currentPending) => [...currentPending, request]);
  };

  const displayItems = getDisplayItems(
    items,
    userMap,
    pendingRequests,
    queuedUpdates,
    queuedDeletedLocalIds,
    itemIdToLocalIdRef.current
  );

  const addItem = (item: ShoppingListRequestAddItem) => {
    // Make room for the new item by incrementing existing ordinals if needed
    const updatedItems: DisplayItemUpdate[] = [];
    for (const displayItem of displayItems) {
      if (displayItem.item.category === item.category && displayItem.item.ordinal >= item.ordinal) {
        updatedItems.push({
          itemLocalId: displayItem.localId,
          ordinal: displayItem.item.ordinal + 1,
        });
      }
    }

    return doRequest({
      addedItems: [item],
      updatedItems,
      deletedItemLocalIds: [],
    });
  };

  const updateItems = (updatedItems: DisplayItemUpdate[]) => {
    return doRequest({
      addedItems: [],
      updatedItems,
      deletedItemLocalIds: [],
    });
  };

  const deleteItems = (deletedItemLocalIds: string[]) => {
    return doRequest({
      addedItems: [],
      updatedItems: [],
      deletedItemLocalIds,
    });
  };

  const hasPendingData =
    pendingRequests.length > 0 || queuedUpdates.length > 0 || queuedDeletedLocalIds.length > 0;

  return {
    hasLoaded,
    isConnected,
    hasPendingData,
    displayItems,
    error,
    addItem,
    updateItems,
    deleteItems,
  };
}
