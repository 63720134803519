import styled from 'styled-components';
import { ErrorText, HeadingText, Spacer, TextButton } from 'src/components/common';
import { useShoppingListItems } from './hooks/useShoppingListItems';
import { Spinner } from 'src/components/Spinner';
import { PageHeading } from 'src/components/PageHeading';
import { ShoppingView } from './ShoppingView';
import { EditingView } from './EditingView';
import { useShoppingList } from './hooks/useShoppingList';
import { useState } from 'react';
import { AppHelmet } from 'src/components/AppHelmet';

const PageContainer = styled.div`
  width: 500px;
  max-width: 100%;
  margin: auto;
  overflow: visible;
  text-align: left;
`;

export function ShoppingListPage({ listId }: { listId: string }) {
  // TODO: show list update loading state
  // TODO: show list update errors
  const { list, updateShoppingList } = useShoppingList(listId);
  const {
    hasLoaded,
    isConnected,
    displayItems,
    hasPendingData,
    error,
    addItem,
    updateItems,
    deleteItems,
  } = useShoppingListItems(listId);

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);

  if (error) {
    return (
      <>
        <AppHelmet context="Error" />
        <PageHeading backButton />
        <Spacer $flex>
          <ErrorText>{error}</ErrorText>
        </Spacer>
      </>
    );
  }

  if (!list || !hasLoaded) {
    return (
      <>
        <AppHelmet context="Loading" />
        <PageHeading backButton />
        <Spinner centered />
      </>
    );
  }

  const setIsDraft = (isDraft: boolean) => {
    updateShoppingList({ isDraft });
  };

  const setCategoryOrder = (categoryOrder: string[]) => {
    updateShoppingList({ categoryOrder });
  };

  // TODO: find a way to make sure errors do not go un-noticed depending on where the user is scrolled
  // TODO: improve edit toggle appearance - possibly a floating slider at the top or bottom?

  // TODO: Hide loading indicator until after some delay
  const context = hasPendingData ? `(...) ${list.name}` : list.name;

  return (
    <>
      <AppHelmet context={context} />
      <PageHeading backButton>
        <HeadingText>{context}</HeadingText>
        <Spacer $mt="4px" $flex $alignCenter>
          {list.isDraft ? (
            <TextButton onClick={() => setIsDraft(false)}>Shopping view</TextButton>
          ) : (
            <TextButton onClick={() => setIsDraft(true)}>Editing view</TextButton>
          )}
          <span>&#65372;</span>
          <TextButton onClick={() => setIsActionsModalOpen(true)}>Actions</TextButton>
        </Spacer>
      </PageHeading>
      <PageContainer>
        {list.isDraft ? (
          <EditingView
            shoppingList={list}
            displayItems={displayItems}
            addItem={addItem}
            updateItems={updateItems}
            deleteItems={deleteItems}
            updateCategoryOrder={setCategoryOrder}
            disabled={!isConnected}
            isActionsModalOpen={isActionsModalOpen}
            onCloseActionsModal={() => setIsActionsModalOpen(false)}
          />
        ) : (
          <ShoppingView
            shoppingList={list}
            displayItems={displayItems}
            updateItem={(request) => updateItems([request])}
            deleteItems={deleteItems}
            disabled={!isConnected}
            isActionsModalOpen={isActionsModalOpen}
            onCloseActionsModal={() => setIsActionsModalOpen(false)}
            onEnterEditingView={() => setIsDraft(true)}
          />
        )}
        {error && (
          <Spacer $mv="12px">
            <ErrorText>{error}</ErrorText>
          </Spacer>
        )}
      </PageContainer>
    </>
  );
}
