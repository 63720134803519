import { ItemSuggestion, ItemSuggestionsResponse } from 'common/apiTypes';
import { LruCache } from 'common/LruCache';
import { useEffect, useRef, useState } from 'react';
import { useAutocompleteClient } from 'src/api/autocomplete';
import { logError } from 'src/components/logging';
import { useCurrentRef } from 'src/hooks/useCurrentRef';

const MAX_SUGGESTION_COUNT = 5;
const CACHED_QUERY_COUNT = 100;
const CACHED_QUERY_TTL_MS = 60 * 1000;

// TODO: remove this in favor of proper HTTP caching
const suggestionCache = new LruCache<string, ItemSuggestionsResponse>(
  CACHED_QUERY_COUNT,
  CACHED_QUERY_TTL_MS
);

export function useItemSuggestions(
  enteredText: string,
  enteredCategory: string,
  allowFetch: boolean
) {
  const { getItemNameSuggestions } = useAutocompleteClient();

  const [suggestionsResponse, setSuggestionsResponse] = useState<ItemSuggestionsResponse>();

  const isFetchingRef = useRef(false);
  const nextFetchRef = useRef('');

  const fetchSuggestions = async (enteredText: string) => {
    const cached = suggestionCache.get(enteredText);
    if (cached) {
      nextFetchRef.current = '';
      setSuggestionsResponse(cached);
      return;
    }

    if (isFetchingRef.current) {
      // To avoid overloading the server, wait until the current fetch completes
      nextFetchRef.current = enteredText;
      return;
    }

    try {
      isFetchingRef.current = true;
      const response = await getItemNameSuggestions(enteredText);

      setSuggestionsResponse(response);

      suggestionCache.add(enteredText, response);
    } catch (err) {
      logError(err);
    } finally {
      isFetchingRef.current = false;
    }

    if (nextFetchRef.current) {
      const next = nextFetchRef.current;
      nextFetchRef.current = '';

      void fetchSuggestions(next);
    }
  };

  const fetchSuggestionsRef = useCurrentRef(fetchSuggestions);

  useEffect(() => {
    if (enteredText && allowFetch) {
      void fetchSuggestionsRef.current(enteredText);
    }
  }, [enteredText, allowFetch, fetchSuggestionsRef]);

  if (!enteredText || !suggestionsResponse) {
    return [];
  }

  const enteredTextLowerTrim = enteredText.toLocaleLowerCase().trim();
  const isExactMatch = ({ name }: ItemSuggestion) =>
    name.toLocaleLowerCase() === enteredTextLowerTrim;
  const isPartialMatch = ({ name }: ItemSuggestion) =>
    name.length > enteredText.length && name.toLocaleLowerCase().startsWith(enteredTextLowerTrim);

  const exactResult = suggestionsResponse.results.find(isExactMatch);
  let filteredResults = suggestionsResponse.results.filter(isPartialMatch);

  if (exactResult?.category && exactResult.category !== enteredCategory) {
    // Include exact match if a category suggestion is available
    filteredResults.unshift(exactResult);
  }
  filteredResults = filteredResults.slice(0, MAX_SUGGESTION_COUNT);

  return filteredResults;
}
