import { RecipeData, UpdateRecipeRequest } from 'common/apiTypes';
import { useEffect, useState } from 'react';
import { optimisticRecipeUpdate, useRecipeClient } from 'src/api/useRecipeClient';
import { logError } from 'src/components/logging';

export function useRecipe(recipeId: string) {
  const { getRecipe, postUpdateRecipe } = useRecipeClient();

  const [isLoading, setIsLoading] = useState(true);
  const [recipe, setRecipe] = useState<RecipeData>();
  const [error, setError] = useState('');

  useEffect(() => {
    const doFetch = async () => {
      try {
        setIsLoading(true);
        setRecipe(await getRecipe({ recipeId }));
      } catch (err) {
        logError(err);
        setError('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };
    void doFetch();
  }, [recipeId, getRecipe]);

  const updateRecipe = (request: Omit<UpdateRecipeRequest, 'recipeId'>) => {
    const doUpdate = async () => {
      try {
        setIsLoading(true);

        if (recipe) {
          setRecipe(optimisticRecipeUpdate(recipe, request));
        }

        // TODO: prevent race conditions between multiple updates
        setRecipe(await postUpdateRecipe({ ...request, recipeId }));
      } catch (err) {
        logError(err);
        setError('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };
    void doUpdate();
  };

  return {
    isLoading,
    recipe,
    error,
    updateRecipe,
  };
}
