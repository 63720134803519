import { Spacer, Text, NakedButton, CloseButton, FlexChild } from 'src/components/common';
import styled from 'styled-components';

const SelectedActionsContainer = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  height: 48px;
  background: white;
  box-shadow: 0px 0px 4px gray;
`;

const SelectedActionsInner = styled.div`
  max-width: 500px;
  height: 100%;
  margin: auto;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

function ModifyButton({ disabled, onClick }: { disabled?: boolean; onClick: () => void }) {
  return (
    <NakedButton onClick={onClick} disabled={disabled}>
      <Text $size="xl">&#9881;</Text>
    </NakedButton>
  );
}

function TrashButton({ disabled, onClick }: { disabled?: boolean; onClick: () => void }) {
  return (
    <NakedButton onClick={onClick} disabled={disabled}>
      <Spacer $mt="2px">
        <Text $size="md">&#128465;</Text>
      </Spacer>
    </NakedButton>
  );
}

export function SelectedActions({
  count,
  onClearSelection,
  onClickChangeCategory,
  onClickDelete,
  disabled,
}: {
  count: number;
  onClearSelection: () => void;
  onClickChangeCategory: () => void;
  onClickDelete: () => void;
  disabled: boolean;
}) {
  return (
    <SelectedActionsContainer>
      <SelectedActionsInner>
        <CloseButton onClick={onClearSelection} size="lg" />
        <FlexChild $grow>
          <Text $size="lg">{count} selected</Text>
        </FlexChild>
        <ModifyButton disabled={disabled} onClick={onClickChangeCategory} />
        <TrashButton disabled={disabled} onClick={onClickDelete} />
      </SelectedActionsInner>
    </SelectedActionsContainer>
  );
}
