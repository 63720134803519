import styled from 'styled-components';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';
import { CategoryDisplayItem } from './hooks/useCategorizedItems';
import { DisplayItemUpdate } from './hooks/useShoppingListItems';
import { DragButton } from 'src/components/DragButton';
import { useItemSuggestions } from './hooks/useItemSuggestions';
import { ItemSuggestion } from 'common/apiTypes';
import { FlexChild } from 'src/components/common';
import { ItemSuggestionList } from './ItemSuggestionList';

const BaseItemRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  padding-left: 12px;
  height: 46px;

  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    margin-left: 12px;
    accent-color: auto;
  }
`;

const ItemRow = styled(BaseItemRow)<{
  $hidden: boolean;
  $even: boolean;
  $isItemChecked: boolean;
  $isRowSelected: boolean;
}>`
  background-color: ${(p) => (p.$isRowSelected ? '#cfdfef' : p.$even ? '#fafafa' : '#f5f5f5')};

  ${(p) =>
    p.$hidden &&
    `
  background-color: white;
  border: 1px solid gray;

  & * {
    visibility: hidden;
  }
  `}

  input[type='text'] {
    ${(p) => p.$isItemChecked && 'text-decoration: line-through;'}
  }
`;

const BlankItemRow = styled(BaseItemRow)`
  padding-right: 18px;
`;

const AddButton = styled.button`
  width: 30px;
  margin-left: 6px;
  padding: 0;
`;

const HiddenDragButton = styled(DragButton)`
  visibility: hidden;
`;

const Hide = styled.div<{ $isVisible: boolean }>`
  ${(p) => !p.$isVisible && 'visibility: hidden;'}
`;

const StyledInput = styled.input`
  width: 100%;
`;

const ItemNameInput = React.forwardRef(function ItemNameInput(
  {
    placeholder,
    value,
    category,
    onChange,
    onAcceptSuggestion,
    onAddItemAfter,
    onBackspaceTruncate,
    disabled,
  }: {
    placeholder?: string;
    value: string;
    category: string;
    onChange: (value: string) => void;
    onAcceptSuggestion: (suggestion: ItemSuggestion) => void;
    onAddItemAfter: () => void;
    onBackspaceTruncate: () => void;
    disabled: boolean;
  },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const [showSuggestionList, setShowSuggestionList] = useState(false);

  const suggestions = useItemSuggestions(value, category, showSuggestionList);

  const handleChange = (value: string) => {
    setShowSuggestionList(true);
    onChange(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onAddItemAfter();
    }

    if (e.key === 'Backspace' && value === '') {
      e.preventDefault();
      onBackspaceTruncate();
    }

    if (e.key === 'Escape') {
      setShowSuggestionList(false);
    }
  };

  const handleBlur = (e: React.FocusEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowSuggestionList(false);
    }
  };

  const handleAcceptSuggestion = (suggestion: ItemSuggestion) => {
    setShowSuggestionList(false);

    onAcceptSuggestion(suggestion);
  };

  return (
    <FlexChild ref={inputContainerRef} $grow style={{ position: 'relative' }} onBlur={handleBlur}>
      <StyledInput
        ref={ref}
        type="text"
        placeholder={placeholder}
        value={value}
        onFocus={() => setShowSuggestionList(true)}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      {showSuggestionList && suggestions.length > 0 && (
        <ItemSuggestionList
          anchorRef={inputContainerRef}
          suggestions={suggestions}
          onAcceptSuggestion={handleAcceptSuggestion}
        />
      )}
    </FlexChild>
  );
});

export const EditingViewBlankItem = forwardRef(function EditingViewBlankItem(
  {
    onAddItem,
    onBackspaceTruncate,
    disabled,
  }: {
    onAddItem: (name: string, category: string, refocus: boolean) => void;
    onBackspaceTruncate: () => void;
    disabled: boolean;
  },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [name, setName] = useState('');

  const handleAcceptSuggestion = (suggestion: ItemSuggestion) => {
    onAddItem(suggestion.name, suggestion.category, true);

    setName('');
  };

  const handleAcceptEntry = (refocus: boolean) => {
    if (!name) {
      return;
    }

    onAddItem(name, /*category=*/ '', refocus);
    setName('');
  };

  const handleBlur = (e: React.FocusEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      handleAcceptEntry(false);
    }
  };

  return (
    <BlankItemRow onBlur={handleBlur}>
      <HiddenDragButton onDragStart={() => undefined} disabled />
      <ItemNameInput
        ref={ref}
        placeholder="Add item"
        value={name}
        category=""
        onChange={setName}
        onAcceptSuggestion={handleAcceptSuggestion}
        onAddItemAfter={() => handleAcceptEntry(true)}
        onBackspaceTruncate={onBackspaceTruncate}
        disabled={disabled}
      />
      <Hide $isVisible={!!name}>
        <AddButton onClick={() => handleAcceptEntry(true)}>&#xFF0B;</AddButton>
      </Hide>
    </BlankItemRow>
  );
});

export const EditingViewItem = forwardRef(function EditingViewItem(
  {
    displayItem,
    isSelected,
    isHidden,
    onUpdateItem,
    onAcceptSuggestion,
    onAddItemAfter,
    onBackspaceTruncate,
    onDragStart,
    onChangeIsSelected,
    disabled,
    dragContainerRef,
  }: {
    displayItem: CategoryDisplayItem;
    isSelected: boolean;
    isHidden: boolean;
    onUpdateItem: (update: DisplayItemUpdate) => void;
    onAcceptSuggestion: (displayItem: CategoryDisplayItem, suggestion: ItemSuggestion) => void;
    onAddItemAfter: () => void;
    onBackspaceTruncate: () => void;
    onDragStart: () => void;
    onChangeIsSelected: (itemId: string, isSelected: boolean) => void;
    disabled: boolean;
    dragContainerRef: React.RefObject<HTMLElement>;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const item = displayItem.item;
  const itemLocalId = displayItem.localId;

  const [name, setName] = useState(item.name);

  const debounce = useDebounce();

  useEffect(() => {
    // TODO: avoid value thrashing if there is already a debounced update pending
    setName(item.name);
  }, [item.name]);

  const handleChangeName = (name: string) => {
    setName(name);

    debounce(() => {
      onUpdateItem({ itemLocalId, name });
    });
  };

  const handleChangeIsSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeIsSelected(itemLocalId, event.target.checked);
  };

  const handleClick = (e: React.MouseEvent) => {
    if (e.target !== e.currentTarget || disabled) {
      return;
    }

    onChangeIsSelected(itemLocalId, !isSelected);
  };

  const handleAcceptSuggestion = (suggestion: ItemSuggestion) => {
    setName(suggestion.name);

    // Clear any previously debounced update
    debounce(() => undefined, /*immediate=*/ true);

    onAcceptSuggestion(displayItem, suggestion);
  };

  return (
    <ItemRow
      ref={ref}
      $hidden={isHidden}
      $even={!!(displayItem.index % 2)}
      $isItemChecked={!!item.isChecked}
      $isRowSelected={isSelected}
      onClick={handleClick}
    >
      <DragButton
        onDragStart={onDragStart}
        dragContainerRef={dragContainerRef}
        disabled={disabled}
      />
      <ItemNameInput
        value={name}
        category={item.category}
        onChange={handleChangeName}
        onAcceptSuggestion={handleAcceptSuggestion}
        onAddItemAfter={onAddItemAfter}
        onBackspaceTruncate={onBackspaceTruncate}
        disabled={disabled}
      />
      <input
        type="checkbox"
        checked={isSelected}
        onChange={handleChangeIsSelected}
        disabled={disabled}
      />
    </ItemRow>
  );
});
