import { Route, Router } from 'src/components/Router';
import { useSession } from 'src/hooks/useSession';

import { LoadingPage } from './LoadingPage';
import { ErrorPage } from './ErrorPage';

export function AuthRouter({
  loggedOutRoutes,
  loggedInRoutes,
}: {
  loggedOutRoutes: Route[];
  loggedInRoutes: Route[];
}) {
  const { currentUser, isLoading, error } = useSession();

  let routes: Route[];
  if (isLoading) {
    routes = [['*', () => <LoadingPage />]];
  } else if (error) {
    routes = [['*', () => <ErrorPage error={error} />]];
  } else if (!currentUser) {
    routes = loggedOutRoutes;
  } else {
    routes = loggedInRoutes;
  }

  return <Router routes={routes} />;
}
