import { AppHelmet } from 'src/components/AppHelmet';
import { ErrorText } from 'src/components/common';

export function ErrorPage({ error }: { error: string }) {
  return (
    <>
      <AppHelmet context="Error" />
      <ErrorText>{error}</ErrorText>
    </>
  );
}
