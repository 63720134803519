import { CreateSessionRequest, CreateSessionResponse, CurrentUser } from 'common/apiTypes';

export function getAuthHeaders(sessionToken: string | undefined) {
  if (!sessionToken) {
    return {};
  }

  return { Authorization: `Bearer ${sessionToken}` };
}

export async function getCurrentUser(sessionToken: string) {
  const response = await fetch(`/api/currentUser`, {
    headers: getAuthHeaders(sessionToken),
  });

  return (await response.json()) as CurrentUser;
}

export async function postCreateSession(request: CreateSessionRequest) {
  const response = await fetch('/api/createSession', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  return (await response.json()) as CreateSessionResponse;
}
