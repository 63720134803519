import { Spinner } from 'src/components/Spinner';
import { ErrorText, HeadingText, Spacer } from 'src/components/common';
import styled from 'styled-components';
import { Link, useNavigate } from 'src/components/Router';
import { PageHeading } from 'src/components/PageHeading';
import { AppHelmet } from 'src/components/AppHelmet';
import { useRecipes } from './hooks/useRecipes';
import { RecipeSummaryData } from 'common/apiTypes';
import { CreateRecipe } from './CreateRecipe';
import { RecipeSummary } from './RecipeSummary';

const CardsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px;
`;

const ViewLinksContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

function ViewLinks() {
  return (
    <ViewLinksContainer>
      <Link href="/trash">Trashed Recipes</Link>
    </ViewLinksContainer>
  );
}

export function RecipesPage({ viewMode = '' }: { viewMode?: '' | 'trash' }) {
  const { navigate } = useNavigate();
  const {
    isLoading,
    orderedRecipes,
    error,
    createRecipe,
    updateRecipe,
    deleteRecipe,
    restoreRecipe,
  } = useRecipes();

  const isRecipeVisible = (recipe: RecipeSummaryData) => {
    if (recipe.deletedAtMs) {
      return viewMode === 'trash';
    }
    return viewMode === '';
  };

  const openRecipe = (recipeId: string) => {
    navigate(`/recipe/${recipeId}`);
  };

  const handleCreate = async (name: string) => {
    const newRecipe = await createRecipe(name);
    openRecipe(newRecipe.recipeId);
  };

  if (isLoading) {
    return <Spinner centered />;
  }

  let context;
  let showCreate = false;
  switch (viewMode) {
    case 'trash':
      context = 'Trashed Recipes';
      break;
    default:
      context = 'Current Recipes';
      showCreate = true;
      break;
  }

  return (
    <>
      <AppHelmet context={context} />
      <PageHeading backButton={viewMode !== ''}>
        <HeadingText>{context}</HeadingText>
      </PageHeading>
      {showCreate && (
        <Spacer $mt="24px" $flex>
          <CreateRecipe onSubmit={handleCreate} />
        </Spacer>
      )}
      {error && (
        <Spacer $mt="24px" $flex>
          <ErrorText>{error}</ErrorText>
        </Spacer>
      )}
      <CardsContainer>
        {orderedRecipes.filter(isRecipeVisible).map((recipe) => (
          <RecipeSummary
            key={recipe.recipeId}
            recipe={recipe}
            isLoading={false}
            isHidden={false}
            onClick={() => openRecipe(recipe.recipeId)}
            onUpdate={updateRecipe}
            onDelete={deleteRecipe}
            onRestore={restoreRecipe}
          />
        ))}
      </CardsContainer>
      {viewMode === '' && <ViewLinks />}
    </>
  );
}
