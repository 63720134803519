import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'src/components/Spinner';
import { ErrorText } from 'src/components/common';
import { logError } from 'src/components/logging';

export function CreateRecipe({ onSubmit }: { onSubmit: (name: string) => Promise<void> }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && nameInputRef.current) {
      nameInputRef.current.focus();
    }
    if (!isEditing) {
      setName('');
      setError('');
      setIsLoading(false);
    }
  }, [isEditing]);

  const handleSubmit = async () => {
    try {
      setError('');
      setIsLoading(true);
      await onSubmit(name);

      setIsEditing(false);
    } catch (err) {
      logError(err);
      setError('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      void handleSubmit();
    }

    if (event.key === 'Escape') {
      setIsEditing(false);
    }
  };

  if (!isEditing) {
    return <button onClick={() => setIsEditing(true)}>+ New Recipe</button>;
  }

  return (
    <div>
      <input
        ref={nameInputRef}
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={isLoading}
      />
      <button onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? <Spinner /> : 'Create Recipe'}
      </button>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
}
