import styled from 'styled-components';
import { CategoryDisplayItem } from './hooks/useCategorizedItems';
import { DisplayItemUpdate } from './hooks/useShoppingListItems';
import { UserPicture } from 'src/components/UserPicture';

const ItemRow = styled.div<{ $even: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  min-height: 46px;
  background-color: ${(p) => (p.$even ? '#fafafa' : '#f5f5f5')};

  &:hover {
    background-color: #cfdfef;
  }
`;

const ItemLabel = styled.label<{ $checked: boolean | undefined; $disabled: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 12px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  user-select: none;
  ${(p) => p.$checked && 'text-decoration: line-through;'}
  ${(p) => p.$disabled && 'color: gray;'}

  input {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    margin-right: 4px;
    margin-bottom: 4px;
  }
`;

const Ghostly = styled.div`
  opacity: 0.5;
`;

export function ShoppingViewItem({
  displayItem,
  onUpdateItem,
  disabled,
}: {
  displayItem: CategoryDisplayItem;
  onUpdateItem: (item: DisplayItemUpdate) => void;
  disabled: boolean;
}) {
  const item = displayItem.item;

  const handleChangeIsChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateItem({
      itemLocalId: displayItem.localId,
      isChecked: event.target.checked,
    });
  };

  return (
    <ItemRow $even={!!(displayItem.index % 2)}>
      <ItemLabel $checked={item.isChecked} $disabled={disabled}>
        <input
          type="checkbox"
          checked={item.isChecked}
          onChange={handleChangeIsChecked}
          disabled={disabled}
        />{' '}
        {item.name}
      </ItemLabel>
      {displayItem.checkedByUser?.pictureUrl && (
        <Ghostly>
          <UserPicture src={displayItem.checkedByUser.pictureUrl} size="xs" />
        </Ghostly>
      )}
    </ItemRow>
  );
}
