import styled from 'styled-components';
import { useRecipe } from './hooks/useRecipe';
import { AppHelmet } from 'src/components/AppHelmet';
import { PageHeading } from 'src/components/PageHeading';
import { ErrorText, HeadingText, Spacer, Text } from 'src/components/common';
import { Spinner } from 'src/components/Spinner';
import { Link } from 'src/components/Router';
import { useEffect, useState } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';

const PageContainer = styled.div`
  width: 500px;
  max-width: 100%;
  margin: auto;
  overflow: visible;
  text-align: left;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  field-sizing: content;
  resize: none;
  padding: 8px;
  line-height: 24px;
`;

function MultilineInput({
  lines,
  onChange,
  placeholder,
}: {
  lines: string[];
  onChange: (lines: string[]) => void;
  placeholder?: string;
}) {
  const handleChange = (text: string) => {
    onChange(text.replaceAll('\r', '').split('\n'));
  };

  const text = lines.join('\n');

  return (
    <StyledTextarea
      value={text}
      onChange={(e) => handleChange(e.target.value)}
      placeholder={placeholder}
    />
  );
}

export function EditRecipePage({ recipeId }: { recipeId: string }) {
  const { recipe, updateRecipe, error, isLoading } = useRecipe(recipeId);

  const [name, setName] = useState('');
  const [ingredients, setIngredients] = useState<string[]>([]);
  const [steps, setSteps] = useState<string[]>([]);

  const debounce = useDebounce();

  useEffect(() => {
    if (recipe) {
      setName(recipe.name);
      setIngredients(recipe.ingredients);
      setSteps(recipe.steps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!recipe]);

  const handleNameChange = (name: string) => {
    setName(name);

    debounce(() => updateRecipe({ name, ingredients, steps }));
  };

  const handleIngredientsChange = (ingredients: string[]) => {
    setIngredients(ingredients);

    debounce(() => updateRecipe({ name, ingredients, steps }));
  };

  const handleStepsChange = (steps: string[]) => {
    setSteps(steps);

    debounce(() => updateRecipe({ name, ingredients, steps }));
  };

  if (error) {
    return (
      <>
        <AppHelmet context="Error" />
        <PageHeading backButton />
        <Spacer $flex>
          <ErrorText>{error}</ErrorText>
        </Spacer>
      </>
    );
  }

  if (!recipe) {
    return (
      <>
        <AppHelmet context="Loading" />
        <PageHeading backButton />
        <Spinner centered />
      </>
    );
  }

  // TODO: find a way to make sure errors do not go un-noticed depending on where the user is scrolled

  // TODO: Hide loading indicator until after some delay
  const loadingPrefix = isLoading ? '(...) ' : '';

  return (
    <>
      <AppHelmet context={`${loadingPrefix}${recipe.name}`} />
      <PageHeading backButton>
        <HeadingText>
          {loadingPrefix}
          Edit recipe:{' '}
          <input type="text" value={name} onChange={(e) => handleNameChange(e.target.value)} />
          <br />
          <Link href={`/recipe/${recipeId}`} disabled={isLoading} replaceHistory>
            Done editing
          </Link>
        </HeadingText>
      </PageHeading>
      <PageContainer>
        {error && (
          <Spacer $mv="12px">
            <ErrorText>{error}</ErrorText>
          </Spacer>
        )}
        <Spacer $mb="16px">
          <Text $size="lg">Ingredients</Text>
        </Spacer>
        <Spacer $mb="32px">
          <MultilineInput
            lines={ingredients}
            onChange={handleIngredientsChange}
            placeholder="Enter ingredients"
          />
        </Spacer>
        <Spacer $mb="16px">
          <Text $size="lg">Steps</Text>
        </Spacer>
        <Spacer $mb="32px">
          <MultilineInput lines={steps} onChange={handleStepsChange} placeholder="Enter steps" />
        </Spacer>
      </PageContainer>
    </>
  );
}
