import { RecipeSummaryData, UpdateRecipeRequest } from 'common/apiTypes';
import { useEffect, useState } from 'react';
import { optimisticRecipeUpdate, useRecipeClient } from 'src/api/useRecipeClient';
import { logError } from 'src/components/logging';

export function useRecipes() {
  const { getRecipes, postCreateRecipe, postUpdateRecipe, postDeleteRecipe, postRestoreRecipe } =
    useRecipeClient();

  const [isLoading, setIsLoading] = useState(true);
  const [recipes, setRecipes] = useState<RecipeSummaryData[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadRecipes = async () => {
      try {
        setIsLoading(true);

        const response = await getRecipes();

        setRecipes(response.recipes);
      } catch (err) {
        logError(err);
        setError('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };
    void loadRecipes();
  }, [getRecipes]);

  const createRecipe = async (name: string) => {
    const recipe = await postCreateRecipe({ name });

    setRecipes((existingRecipes) => [recipe, ...existingRecipes]);

    return recipe;
  };

  const updateRecipe = (request: UpdateRecipeRequest) => {
    const doUpdate = async () => {
      setRecipes((recipes) =>
        recipes.map((recipe) =>
          recipe.recipeId === request.recipeId ? optimisticRecipeUpdate(recipe, request) : recipe
        )
      );

      const newRecipe = await postUpdateRecipe(request);

      setRecipes((recipes) =>
        recipes.map((recipe) => (recipe.recipeId === newRecipe.recipeId ? newRecipe : recipe))
      );
    };
    void doUpdate();
  };

  const deleteRecipe = (recipeId: string) => {
    const doDelete = async () => {
      const recipe = await postDeleteRecipe({ recipeId });

      setRecipes((existingRecipes) =>
        existingRecipes.map((existingRecipe) =>
          existingRecipe.recipeId === recipeId ? recipe : existingRecipe
        )
      );
    };
    void doDelete();
  };

  const restoreRecipe = (recipeId: string) => {
    const doRestore = async () => {
      const recipe = await postRestoreRecipe({ recipeId });

      setRecipes((existingRecipes) =>
        existingRecipes.map((existingRecipe) =>
          existingRecipe.recipeId === recipeId ? recipe : existingRecipe
        )
      );
    };
    void doRestore();
  };

  return {
    isLoading,
    orderedRecipes: recipes,
    error,
    createRecipe,
    updateRecipe,
    deleteRecipe,
    restoreRecipe,
  };
}
