import { useCallback, useEffect, useRef } from 'react';

export function useDebounce(durationMs = 500) {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const cancel = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => cancel, []);

  const debounce = useCallback(
    (debouncedFn: () => void, immediate = false) => {
      cancel();

      if (immediate) {
        debouncedFn();
      } else {
        timerRef.current = setTimeout(debouncedFn, durationMs);
      }
    },
    [durationMs]
  );

  return debounce;
}
