import React, { useEffect, useState } from 'react';
import { Modal } from 'src/components/Modal';
import styled from 'styled-components';
import { useCategoryOptions } from './hooks/useCategoryOptions';
import { Text } from 'src/components/common';

const RadioContainer = styled.div`
  margin: 24px 0;

  & label {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  & input[type='radio'] {
    margin-right: 12px;
  }
`;

export function CategorySelectModal({
  isOpen,
  initialCategory,
  currentCategories,
  onSelect,
  onCancel,
}: {
  isOpen: boolean;
  initialCategory: string | undefined;
  currentCategories: string[];
  onSelect: (category: string) => void;
  onCancel: () => void;
}) {
  const allCategories = useCategoryOptions(currentCategories, isOpen);

  const [selected, setSelected] = useState(initialCategory || '');
  const [customCategory, setCustomCategory] = useState('');

  useEffect(() => {
    if (isOpen) {
      setSelected(initialCategory || '');
      setCustomCategory('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value);
  };

  const handleChangeCustomCategory = (customCategory: string) => {
    setCustomCategory(customCategory);
    setSelected(customCategory);
  };

  const handleClickSubmit = () => {
    onSelect(selected);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClickSubmit();
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onCancel}>
      <Text $size="lg" $centered>
        Select Category
      </Text>
      <RadioContainer>
        {allCategories.map((category) => (
          <label key={category}>
            <input
              type="radio"
              value={category}
              name="category-selection"
              checked={selected === category}
              onChange={handleChange}
            />
            {category}
          </label>
        ))}
        <label>
          <input
            type="radio"
            value={customCategory}
            name="category-selection"
            checked={selected === customCategory}
            onChange={handleChange}
          />
          <input
            type="text"
            value={customCategory}
            onClick={() => setSelected(customCategory)}
            onChange={(e) => handleChangeCustomCategory(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Other"
          />
        </label>
      </RadioContainer>
      <button onClick={handleClickSubmit}>Save</button>
    </Modal>
  );
}
