import { ItemSuggestionsResponse } from 'common/apiTypes';
import { useRef } from 'react';
import { useCurrentRef } from 'src/hooks/useCurrentRef';
import { useSession } from 'src/hooks/useSession';
import { getAuthHeaders } from './auth';

export function useAutocompleteClient() {
  const { sessionToken } = useSession();

  async function getItemNameSuggestions(enteredText: string) {
    const response = await fetch(`/api/itemNameSuggestions?q=${encodeURIComponent(enteredText)}`, {
      headers: getAuthHeaders(sessionToken),
    });

    return (await response.json()) as ItemSuggestionsResponse;
  }

  async function getCategorySuggestions() {
    const response = await fetch(`/api/categorySuggestions`, {
      headers: getAuthHeaders(sessionToken),
    });

    return (await response.json()) as string[];
  }

  const currentRef = useCurrentRef({
    getItemNameSuggestions,
    getCategorySuggestions,
  });

  const staticRef = useRef({
    getItemNameSuggestions: (enteredText: string) =>
      currentRef.current.getItemNameSuggestions(enteredText),
    getCategorySuggestions: () => currentRef.current.getCategorySuggestions(),
  });

  return staticRef.current;
}
