import {
  CreateRecipeRequest,
  DeleteRecipeRequest,
  GetRecipesResponse,
  RestoreRecipeRequest,
  RecipeData,
  UpdateRecipeRequest,
  GetRecipeRequest,
  RecipeSummaryData,
} from 'common/apiTypes';
import { useRef } from 'react';
import { useCurrentRef } from 'src/hooks/useCurrentRef';
import { useSession } from 'src/hooks/useSession';
import { getAuthHeaders } from './auth';

// Note: changes to archived/trashed status are not optimistically updated
export function optimisticRecipeUpdate<T extends RecipeSummaryData | RecipeData>(
  current: T,
  request: Omit<UpdateRecipeRequest, 'recipeId'>
): T {
  if ('ingredients' in current) {
    return {
      ...current,
      name: request.name ?? current.name,
      category: request.category ?? current.category,
      ingredients: request.ingredients ?? current.ingredients,
      steps: request.steps ?? current.steps,
    };
  }

  return {
    ...current,
    name: request.name ?? current.name,
    category: request.category ?? current.category,
  };
}

export function useRecipeClient() {
  const { sessionToken } = useSession();

  async function getRecipes() {
    const response = await fetch('/api/recipes', {
      headers: getAuthHeaders(sessionToken),
    });

    return (await response.json()) as GetRecipesResponse;
  }

  async function getRecipe(request: GetRecipeRequest) {
    const response = await fetch(`/api/getRecipe`, {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as RecipeData;
  }

  async function postCreateRecipe(request: CreateRecipeRequest) {
    const response = await fetch('/api/createRecipe', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as RecipeData;
  }

  async function postUpdateRecipe(request: UpdateRecipeRequest) {
    const response = await fetch('/api/updateRecipe', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as RecipeData;
  }

  async function postDeleteRecipe(request: DeleteRecipeRequest) {
    const response = await fetch('/api/deleteRecipe', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as RecipeData;
  }

  async function postRestoreRecipe(request: RestoreRecipeRequest) {
    const response = await fetch('/api/restoreRecipe', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as RecipeData;
  }

  const currentRef = useCurrentRef({
    getRecipes,
    getRecipe,
    postCreateRecipe,
    postUpdateRecipe,
    postDeleteRecipe,
    postRestoreRecipe,
  });

  const staticRef = useRef({
    getRecipes: () => currentRef.current.getRecipes(),
    getRecipe: (request: GetRecipeRequest) => currentRef.current.getRecipe(request),
    postCreateRecipe: (request: CreateRecipeRequest) =>
      currentRef.current.postCreateRecipe(request),
    postUpdateRecipe: (request: UpdateRecipeRequest) =>
      currentRef.current.postUpdateRecipe(request),
    postDeleteRecipe: (request: DeleteRecipeRequest) =>
      currentRef.current.postDeleteRecipe(request),
    postRestoreRecipe: (request: RestoreRecipeRequest) =>
      currentRef.current.postRestoreRecipe(request),
  });

  return staticRef.current;
}
