import { ErrorText, HeadingText, Spacer, Text } from 'src/components/common';
import { PageHeading } from 'src/components/PageHeading';
import { GoogleSignInButton } from './GoogleSignInButton';
import type { CredentialResponse } from 'google-one-tap';
import { postCreateSession } from 'src/api/auth';
import { useEffect, useState } from 'react';
import { useSession } from 'src/hooks/useSession';
import { logError } from 'src/components/logging';
import { AppHelmet, AppName } from 'src/components/AppHelmet';
import { AppIcon } from 'src/components/AppIcon';
import { useNavigate } from 'src/components/Router';

export function LoginPage() {
  const [error, setError] = useState('');

  const { currentUser, onCreateSession } = useSession();
  const { navigate } = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  if (currentUser) {
    return null;
  }

  const handleGoogleSuccess = async (googleResponse: CredentialResponse) => {
    try {
      const response = await postCreateSession({ googleToken: googleResponse.credential });

      onCreateSession(response.sessionToken, response.currentUser);
    } catch (err) {
      logError(err);
      setError('Something went wrong, please try again.');
    }
  };

  return (
    <>
      <AppHelmet context="Sign up or log in" />
      <PageHeading breakoutContent>
        <HeadingText>
          Sign up or log in to <AppName />
        </HeadingText>
        <Spacer $pt="24px">
          <AppIcon />
        </Spacer>
      </PageHeading>
      <Spacer>
        <GoogleSignInButton onSuccess={handleGoogleSuccess} />
      </Spacer>
      {error && <ErrorText>{error}</ErrorText>}
      <Spacer $pt="24px" $ph="24px">
        <Text $size="sm" $centered $muted>
          By signing up, you agree to the{' '}
          <a href="/terms-of-service" target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
          .
        </Text>
      </Spacer>
    </>
  );
}
