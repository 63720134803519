import { Spacer, Text } from 'src/components/common';
import { DisplayItemUpdate, ShoppingListDisplayItem } from './hooks/useShoppingListItems';
import { CategoryDisplayItem, useCategorizedItems } from './hooks/useCategorizedItems';
import { ShoppingViewItem } from './ShoppingViewItem';
import { ShoppingListData } from 'common/apiTypes';
import { Modal } from 'src/components/Modal';
import { useState } from 'react';
import styled from 'styled-components';

const PaddedText = styled.span<{ $rotated: boolean }>`
  display: inline-block;
  padding: 8px;
  line-height: 100%;
  font-size: 16px;
  ${(p) => p.$rotated && 'transform: rotate(90deg);'}
`;

const InlineText = styled(Text)`
  display: inline;
`;

const LineThroughText = styled(InlineText)<{ $hasLineThrough: boolean }>`
  ${(p) => p.$hasLineThrough && 'text-decoration: line-through;'}
`;

const StyledSpacer = styled(Spacer)`
  user-select: none;
  cursor: pointer;
`;

function ShoppingListActionsModal({
  isOpen,
  onCancel,
  onClickDeleteChecked,
  disabled,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onClickDeleteChecked: () => void;
  disabled: boolean;
}) {
  return (
    <Modal isOpen={isOpen} onCancel={onCancel}>
      <button onClick={onClickDeleteChecked} disabled={disabled}>
        Delete checked items
      </button>
    </Modal>
  );
}

function CollapseButton({ isCollapsed }: { isCollapsed: boolean }) {
  return <PaddedText $rotated={!isCollapsed}>&#x276F;</PaddedText>;
}

function CategoryItems({
  category,
  categoryDisplayItems,
  hideName,
  userCollapsed,
  setUserCollapsed,
  updateItem,
  disabled,
}: {
  category: string;
  categoryDisplayItems: CategoryDisplayItem[];
  hideName: boolean;
  userCollapsed: boolean | undefined;
  setUserCollapsed: (isCollapsed: boolean) => void;
  updateItem: (request: DisplayItemUpdate) => void;
  disabled: boolean;
}) {
  let isCollapsed = userCollapsed;
  if (isCollapsed === undefined) {
    isCollapsed = categoryDisplayItems.every((displayItem) => displayItem.item.isChecked);
  }
  if (hideName) {
    isCollapsed = false;
  }

  const nonEmptyItems = categoryDisplayItems.filter(({ item }) => !!item.name.trim().length);
  const nonEmptyItemCount = nonEmptyItems.length;
  const nonEmptyCheckedItemCount = nonEmptyItems.filter(({ item }) => item.isChecked).length;

  return (
    <Spacer>
      {!hideName && (
        <StyledSpacer $m="12px" $mt="24px" onClick={() => setUserCollapsed(!isCollapsed)}>
          <LineThroughText
            $size="lg"
            $hasLineThrough={nonEmptyCheckedItemCount === nonEmptyItemCount}
          >
            {category || 'No category'}
          </LineThroughText>
          <CollapseButton isCollapsed={isCollapsed} />
          {isCollapsed && (
            <InlineText $size="lg">
              ({nonEmptyCheckedItemCount} / {nonEmptyItemCount})
            </InlineText>
          )}
        </StyledSpacer>
      )}
      {!isCollapsed &&
        categoryDisplayItems.map((displayItem) => (
          <ShoppingViewItem
            key={displayItem.localId}
            displayItem={displayItem}
            onUpdateItem={updateItem}
            disabled={disabled}
          />
        ))}
    </Spacer>
  );
}

export function ShoppingView({
  shoppingList,
  displayItems,
  updateItem,
  deleteItems,
  disabled,
  isActionsModalOpen,
  onCloseActionsModal,
  onEnterEditingView,
}: {
  shoppingList: ShoppingListData;
  displayItems: ShoppingListDisplayItem[];
  updateItem: (request: DisplayItemUpdate) => void;
  deleteItems: (itemLocalIds: string[]) => void;
  disabled: boolean;
  isActionsModalOpen: boolean;
  onCloseActionsModal: () => void;
  onEnterEditingView: () => void;
}) {
  const { categoriesAndItems, currentCategories } = useCategorizedItems(shoppingList, displayItems);

  const [userCollapsedByCategory, setUserCollapsedByCategory] = useState(
    new Map<string, boolean>()
  );

  const setUserCollapsedCategory = (category: string, isCollapsed: boolean | undefined) => {
    setUserCollapsedByCategory((existing) => {
      const updated = new Map(existing);
      if (isCollapsed === undefined) {
        updated.delete(category);
      } else {
        updated.set(category, isCollapsed);
      }
      return updated;
    });
  };

  const handleUpdateItem = (request: DisplayItemUpdate) => {
    updateItem(request);

    if (request.isChecked) {
      // After setting an item to checked, fall back to default collapse behavior (collapse if all items are checked)
      const updatedDisplayItem = categoriesAndItems
        .flatMap(({ categoryDisplayItems }) => categoryDisplayItems)
        .find(({ localId }) => localId === request.itemLocalId);
      if (updatedDisplayItem) {
        setUserCollapsedCategory(updatedDisplayItem.item.category, undefined);
      }
    }
  };

  const handleClickDeleteChecked = () => {
    const checkedLocalIds = displayItems
      .filter(({ item }) => item.isChecked)
      .map(({ localId }) => localId);

    if (checkedLocalIds.length) {
      deleteItems(checkedLocalIds);
    }

    if (checkedLocalIds.length === displayItems.length) {
      // List is now empty, no reason to stay in shopping view
      onEnterEditingView();
    }

    onCloseActionsModal();
  };

  // Only show names if categories are present (excluding the default)
  const hideCategoryNames = !currentCategories.length;

  return (
    <>
      <Spacer $mv="12px">
        {categoriesAndItems.length ? (
          categoriesAndItems.map(({ category, categoryDisplayItems }) => (
            <CategoryItems
              key={category}
              category={category}
              categoryDisplayItems={categoryDisplayItems}
              hideName={hideCategoryNames}
              userCollapsed={userCollapsedByCategory.get(category)}
              setUserCollapsed={(isCollapsed) => setUserCollapsedCategory(category, isCollapsed)}
              updateItem={handleUpdateItem}
              disabled={disabled}
            />
          ))
        ) : (
          <Spacer $m="24px">
            <Text $centered>This list is empty</Text>
          </Spacer>
        )}
      </Spacer>
      <ShoppingListActionsModal
        isOpen={isActionsModalOpen}
        onCancel={onCloseActionsModal}
        onClickDeleteChecked={handleClickDeleteChecked}
        disabled={disabled}
      />
    </>
  );
}
