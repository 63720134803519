import { LinkedList, ListNode } from './LinkedList';

class CacheEntry<K, V> {
  public expiresAtMs: number;
  public lastAddedNode: ListNode<K> | undefined;
  public lastUsedNode: ListNode<K> | undefined;

  constructor(
    public key: K,
    public value: V,
    ttlMs: number
  ) {
    this.expiresAtMs = Date.now() + ttlMs;
  }

  expired() {
    return Date.now() > this.expiresAtMs;
  }
}

export class LruCache<K, V> {
  private entryByKey = new Map<K, CacheEntry<K, V>>();
  private lastAddedEntries = new LinkedList<K>();
  private lastUsedEntries = new LinkedList<K>();

  constructor(
    private maxEntries: number,
    private ttlMs: number
  ) {}

  get(key: K): V | undefined {
    const entry = this.entryByKey.get(key);
    if (!entry) {
      return undefined;
    }

    if (entry.expired()) {
      this.remove(key);
      return undefined;
    }

    if (entry.lastUsedNode) {
      this.lastUsedEntries.remove(entry.lastUsedNode);
    }
    entry.lastUsedNode = this.lastUsedEntries.add(key);

    return entry.value;
  }

  remove(key: K) {
    const entry = this.entryByKey.get(key);
    if (!entry) {
      return;
    }

    if (entry.lastUsedNode) {
      this.lastUsedEntries.remove(entry.lastUsedNode);
    }
    if (entry.lastAddedNode) {
      this.lastAddedEntries.remove(entry.lastAddedNode);
    }
    this.entryByKey.delete(key);
  }

  add(key: K, value: V) {
    this.remove(key);

    if (this.entryByKey.size >= this.maxEntries) {
      this.evict();
    }

    const entry = new CacheEntry<K, V>(key, value, this.ttlMs);
    entry.lastAddedNode = this.lastAddedEntries.add(key);
    entry.lastUsedNode = this.lastUsedEntries.add(key);
    this.entryByKey.set(key, entry);
  }

  private expired(key: K) {
    return this.entryByKey.get(key)?.expired();
  }

  private evict() {
    const lastAddedKey = this.lastAddedEntries.peek();
    if (lastAddedKey && this.expired(lastAddedKey)) {
      this.remove(lastAddedKey);
      return;
    }

    const lastUsedKey = this.lastUsedEntries.peek();
    if (!lastUsedKey) {
      throw new Error('No key to remove');
    }

    this.remove(lastUsedKey);
  }
}
