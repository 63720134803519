import { Helmet } from 'react-helmet-async';

const APP_NAME = 'Baskitt';
const APP_TAGLINE = 'Fast Shopping Lists';

export function LongAppName() {
  return (
    <>
      {APP_NAME}: {APP_TAGLINE}
    </>
  );
}

export function AppName() {
  return <>{APP_NAME}</>;
}

export function LongAppHelmet() {
  return (
    <Helmet>
      <title>
        {APP_NAME}: {APP_TAGLINE}
      </title>
    </Helmet>
  );
}

export function AppHelmet({ context }: { context: string }) {
  return (
    <Helmet>
      <title>
        {context} - {APP_NAME}
      </title>
    </Helmet>
  );
}
