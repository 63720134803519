import { UpdateShoppingListOrderRequest } from 'common/apiTypes';
import { ShoppingListState, getListStatus } from './useShoppingLists';

export function useListDragHandlers({
  listElements,
  updateShoppingListOrder,
  orderedListStates,
  dragListState,
  setDragListId,
  setDragListToIndex,
}: {
  listElements: Map<ShoppingListState, HTMLElement>;
  updateShoppingListOrder: (requset: UpdateShoppingListOrderRequest) => void;
  orderedListStates: ShoppingListState[];
  dragListState: ShoppingListState | undefined;
  setDragListId: (listId: string) => void;
  setDragListToIndex: (index: number) => void;
}) {
  const handleDragStart = (listId: string, listIndex: number) => {
    setDragListId(listId);
    setDragListToIndex(listIndex);
  };

  const handleDragMove = ([cursorX, cursorY]: [number, number]) => {
    if (!dragListState) {
      return;
    }

    const clampedCursorX = Math.max(0, Math.min(window.innerWidth - 1, cursorX));
    const clampedCursorY = Math.max(0, Math.min(window.innerHeight - 1, cursorY));

    // List ordering is isolated by status
    const listStatesForStatus = orderedListStates.filter(
      (state) => getListStatus(state) === getListStatus(dragListState)
    );

    for (const [index, listState] of Array.from(listStatesForStatus.entries())) {
      if (listState === dragListState) {
        continue;
      }

      const el = listElements.get(listState);
      if (!el) {
        continue;
      }

      const rect = el.getBoundingClientRect();

      // Avoid inserting off-screen
      if (rect.top <= 24) {
        continue;
      }
      if (rect.bottom >= window.innerHeight - 24) {
        continue;
      }

      if (
        clampedCursorX >= rect.left &&
        clampedCursorX < rect.right &&
        clampedCursorY >= rect.top &&
        clampedCursorY < rect.bottom
      ) {
        setDragListToIndex(index);
        break;
      }
    }
  };

  const handleDragEnd = () => {
    if (!dragListState) {
      return;
    }

    const dragListStatus = getListStatus(dragListState);
    // List ordering is isolated by status
    const listIdOrder = orderedListStates
      .filter((state) => getListStatus(state) === dragListStatus)
      .map(({ list }) => list.shoppingListId);
    switch (dragListStatus) {
      case 'archived':
        updateShoppingListOrder({ archiveListIdOrder: listIdOrder });
        break;

      case '':
        updateShoppingListOrder({ normalListIdOrder: listIdOrder });
        break;

      default:
        throw new Error(`list ordering not implemented for ${dragListStatus}`);
    }

    setDragListId('');
  };

  return { handleDragStart, handleDragMove, handleDragEnd };
}
