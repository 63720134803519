import type { CredentialResponse } from 'google-one-tap';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'src/components/Spinner';
import { useCurrentRef } from 'src/hooks/useCurrentRef';
import styled from 'styled-components';

// TODO: load client ID from config
const GOOGLE_CLIENT_ID = '59223096327-i1fu4oeh0la3d5t2894a4d4nr1h601ke.apps.googleusercontent.com';

const StyledOuter = styled.div`
  display: inline-block;
  margin: auto;
`;

const StyledInner = styled.div<{ $isHidden: boolean }>`
  ${(p) => p.$isHidden && 'visibility: hidden;'}
`;

export function GoogleSignInButton({
  onSuccess,
}: {
  onSuccess: (response: CredentialResponse) => void;
}) {
  const [isLoading, setIsLoading] = useState(true);

  const buttonRef = useRef<HTMLDivElement>(null);

  const onSuccessRef = useCurrentRef(onSuccess);

  useEffect(() => {
    const doAsync = async () => {
      const googleAccounts = await googleAccountsPromise;

      if (!buttonRef.current) {
        throw new Error('Missing buttonRef');
      }

      googleAccounts.id.initialize({
        // TODO: load the client ID from the server
        client_id: GOOGLE_CLIENT_ID,
        callback: (response) => onSuccessRef.current(response),
      });

      googleAccounts.id.renderButton(buttonRef.current, { text: 'continue_with' });

      setIsLoading(false);
    };

    void doAsync();
  }, [onSuccessRef]);

  return (
    <StyledOuter>
      <StyledInner ref={buttonRef} $isHidden={isLoading}></StyledInner>
      {isLoading && <Spinner centered />}
    </StyledOuter>
  );
}
