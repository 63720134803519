import { useEffect, useReducer, useRef } from 'react';
import { useCursorClientPosition } from 'src/hooks/useCursorClientPosition';
import styled from 'styled-components';

const StyledDiv = styled.div.attrs<{ $hideShadow: boolean; $x: number | undefined; $y: number }>(
  (p) =>
    p.$x !== undefined
      ? {
          style: { top: `${p.$y}px`, left: `${p.$x}px`, transform: 'translate(-50%, -50%)' },
        }
      : { style: { top: `${p.$y}px`, left: '0', right: '0', transform: 'translate(0, -50%)' } }
)`
  position: absolute;
  z-index: 110;
  opacity: 0.75;
  ${(p) => !p.$hideShadow && 'box-shadow: 5px 5px 5px gray;'}
`;

const SCROLL_INTERVAL_MS = 20;
const MAX_SCROLL_RATE = 300;
const SCROLL_THRESHOLD_PX = 100;

export function DragContainer({
  parent,
  onScroll,
  hideShadow = false,
  unlockX = false,
  children,
}: {
  parent: HTMLElement;
  onScroll: (cursorPosition: [number, number]) => void;
  hideShadow?: boolean;
  unlockX?: boolean;
  children: React.ReactNode;
}) {
  const cursor = useCursorClientPosition();

  const cursorRef = useRef(cursor);
  cursorRef.current = cursor;

  const onScrollRef = useRef(onScroll);
  onScrollRef.current = onScroll;

  const [, forceUpdate] = useReducer((count: number) => count + 1, 0);

  useEffect(() => {
    const interval = setInterval(() => {
      const [, cursorY] = cursorRef.current;
      let deltaPx;
      if (cursorY < SCROLL_THRESHOLD_PX) {
        deltaPx = -(MAX_SCROLL_RATE * SCROLL_INTERVAL_MS) / 1000;
      }
      if (cursorY >= window.innerHeight - SCROLL_THRESHOLD_PX) {
        deltaPx = (MAX_SCROLL_RATE * SCROLL_INTERVAL_MS) / 1000;
      }

      if (deltaPx) {
        window.scrollTo({ top: window.scrollY + deltaPx });
        onScrollRef.current(cursorRef.current);
        forceUpdate();
      }
    }, SCROLL_INTERVAL_MS);

    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  const [cursorX, cursorY] = cursor;
  const parentRect = parent.getBoundingClientRect();

  return (
    <StyledDiv
      $hideShadow={hideShadow}
      $x={unlockX ? cursorX - parentRect.left : undefined}
      $y={cursorY - parentRect.top}
    >
      {children}
    </StyledDiv>
  );
}
