import { CategoryWithItems } from './useCategorizedItems';

export function useCategoryDragHandlers({
  categoryElements,
  updateCategoryOrder,
  categoriesAndItems,
  dragCategory,
  setDragCategory,
  setDragCategoryToIndex,
}: {
  categoryElements: Map<string, HTMLElement>;
  updateCategoryOrder: (categoryOrder: string[]) => void;
  categoriesAndItems: CategoryWithItems[];
  dragCategory: string | undefined;
  setDragCategory: (category: string | undefined) => void;
  setDragCategoryToIndex: (index: number) => void;
}) {
  const handleDragStart = (category: string, categoryIndex: number) => {
    setDragCategory(category);
    setDragCategoryToIndex(categoryIndex);
  };

  const handleDragMove = ([, cursorY]: [number, number]) => {
    if (dragCategory === undefined) {
      return;
    }

    const clampedCursorY = Math.max(0, Math.min(window.innerHeight - 1, cursorY));

    let nearest;
    let indexOffset = 0;
    for (const [index, { category }] of Array.from(categoriesAndItems.entries())) {
      if (category === dragCategory) {
        indexOffset = -1;
        continue;
      }

      // Exclude uncategorized section
      if (!category) {
        continue;
      }

      const el = categoryElements.get(category);
      if (!el) {
        continue;
      }

      const rect = el.getBoundingClientRect();
      const distance = Math.abs(clampedCursorY - (rect.top + rect.bottom) / 2);
      if (!nearest || distance < nearest.distance) {
        nearest = { distance, category, index: index + indexOffset, rect };
      }
    }

    if (nearest) {
      let insertAfter = clampedCursorY > (nearest.rect.top + nearest.rect.bottom) / 2;

      // Try to avoid inserting off-screen (but prefer inserting after if needed)
      if (nearest.rect.top < 0) {
        insertAfter = true;
      } else if (nearest.rect.bottom >= window.innerHeight - 14) {
        insertAfter = false;
      }

      setDragCategoryToIndex(nearest.index + (insertAfter ? 1 : 0));
    }
  };

  const handleDragEnd = () => {
    if (dragCategory === undefined) {
      return;
    }

    const categoryOrder = categoriesAndItems.map(({ category }) => category).filter(Boolean);
    updateCategoryOrder(categoryOrder);

    setDragCategory(undefined);
  };

  return { handleDragStart, handleDragMove, handleDragEnd };
}
