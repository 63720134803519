import { Spacer, Text } from 'src/components/common';
import { DisplayItemUpdate, ShoppingListDisplayItem } from './hooks/useShoppingListItems';
import { ItemSuggestion, ShoppingListData, ShoppingListRequestAddItem } from 'common/apiTypes';
import {
  CategoryDisplayItem,
  CategoryWithItems,
  useCategorizedItems,
} from './hooks/useCategorizedItems';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CategorySelectModal } from './CategorySelectModal';
import { EditingViewBlankItem, EditingViewItem } from './EditingViewItem';
import { DragContainer } from './DragContainer';
import { CursorContextProvider } from 'src/hooks/useCursorClientPosition';
import { SelectedActions } from './SelectionActions';
import { useItemSelectionState } from './hooks/useItemSelectionState';
import { useItemDragHandlers } from './hooks/useItemDragHandlers';
import { DragButton } from 'src/components/DragButton';
import { useCategoryDragHandlers } from './hooks/useCategoryDragHandlers';
import { Modal } from 'src/components/Modal';

const CategoriesAndItemsContainer = styled.div`
  position: relative;
`;

const CategoryContainer = styled.div<{ $isCollapsed: boolean }>`
  ${(p) =>
    p.$isCollapsed &&
    `
  position: relative;
  top: 12px;
  box-sizing: content-box;
  height: 2px;
  margin: -1px 0;
  background: blue;

  & * {
    display: none;
  }
  `}
`;

const CategoryTitleContainer = styled.div`
  background: white;
  padding: 12px;
`;

function getEndOfCategory(categoriesAndItems: CategoryWithItems[], category: string) {
  const categoryDisplayItems =
    categoriesAndItems.find((categoryAndItems) => categoryAndItems.category === category)
      ?.categoryDisplayItems ?? [];
  const maxOrdinal = categoryDisplayItems.reduce(
    (maxOrdinal, displayItem) => Math.max(maxOrdinal, displayItem.item.ordinal),
    0
  );

  return [maxOrdinal + 1, categoryDisplayItems.length];
}

function ShoppingListActionsModal({
  isOpen,
  onCancel,
  onClickSelectAll,
  disabled,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onClickSelectAll: () => void;
  disabled: boolean;
}) {
  return (
    <Modal isOpen={isOpen} onCancel={onCancel}>
      <button onClick={onClickSelectAll} disabled={disabled}>
        Select all items
      </button>
    </Modal>
  );
}

function CategoryTitle({
  category,
  disabled,
  onDragStart,
  dragContainerRef,
}: {
  category: string;
  disabled: boolean;
  onDragStart: () => void;
  dragContainerRef: React.RefObject<HTMLElement>;
}) {
  // Uncategorized section cannot be moved
  const canDragCategory = !!category;

  return (
    <CategoryTitleContainer>
      <Text $size="lg">
        {category || 'No category'}
        {canDragCategory && (
          <DragButton
            onDragStart={onDragStart}
            dragContainerRef={dragContainerRef}
            disabled={disabled}
          />
        )}
      </Text>
    </CategoryTitleContainer>
  );
}

function CategoriesAndItems({
  addItem,
  updateItems,
  deleteItems,
  updateCategoryOrder,
  categoriesAndItems,
  currentCategories,
  dragDisplayItem,
  dragCategory,
  setDragItemLocalId,
  setDragItemToCategory,
  setDragItemToIndex,
  setDragCategory,
  setDragCategoryToIndex,
  selectedItemLocalIds,
  setIsSelected,
  disabled,
}: {
  addItem: (request: ShoppingListRequestAddItem) => void;
  updateItems: (updates: DisplayItemUpdate[]) => void;
  deleteItems: (itemLocalIds: string[]) => void;
  updateCategoryOrder: (categoryOrder: string[]) => void;
  categoriesAndItems: CategoryWithItems[];
  currentCategories: string[];
  dragDisplayItem: CategoryDisplayItem | undefined;
  dragCategory: string | undefined;
  setDragItemLocalId: (localId: string | undefined) => void;
  setDragItemToCategory: (category: string) => void;
  setDragItemToIndex: (index: number) => void;
  setDragCategory: (category: string | undefined) => void;
  setDragCategoryToIndex: (index: number) => void;
  selectedItemLocalIds: Set<string>;
  setIsSelected: (localId: string, isSelected: boolean) => void;
  disabled: boolean;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const categoryRefs = useRef(new Map<string, HTMLElement>());
  const itemRefs = useRef(new Map<CategoryDisplayItem, HTMLElement>());
  const blankItemRef = useRef<HTMLInputElement>(null);

  const [indexNeedsFocus, setIndexNeedsFocus] = useState<{ category: string; index: number }>();
  const [blankItemNeedsScroll, setBlankItemNeedsScroll] = useState(false);

  useEffect(() => {
    if (indexNeedsFocus) {
      for (const [displayItem, ref] of Array.from(itemRefs.current.entries())) {
        if (
          displayItem.item.category === indexNeedsFocus.category &&
          displayItem.index === indexNeedsFocus.index
        ) {
          const inputEl = ref.querySelector('input[type="text"]');
          if (inputEl instanceof HTMLInputElement) {
            ref.scrollIntoView({ block: 'nearest' });
            inputEl.focus();
          }

          setIndexNeedsFocus(undefined);

          break;
        }
      }
    }
  }, [indexNeedsFocus, categoriesAndItems]);

  useEffect(() => {
    if (blankItemNeedsScroll) {
      setBlankItemNeedsScroll(false);

      blankItemRef.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [blankItemNeedsScroll]);

  const updateCategoryRef = (category: string, el: HTMLElement | null) => {
    if (el) {
      categoryRefs.current.set(category, el);
    } else {
      categoryRefs.current.delete(category);
    }
  };

  const updateItemRef = (displayItem: CategoryDisplayItem, el: HTMLElement | null) => {
    if (el) {
      itemRefs.current.set(displayItem, el);
    } else {
      itemRefs.current.delete(displayItem);
    }
  };

  const handleAcceptSuggestion = (displayItem: CategoryDisplayItem, suggestion: ItemSuggestion) => {
    const category = suggestion.category || displayItem.item.category;

    const update: DisplayItemUpdate = {
      itemLocalId: displayItem.localId,
      name: suggestion.name,
      category,
    };

    // If moving to a different category, place at the end
    if (category !== displayItem.item.category) {
      const [ordinal] = getEndOfCategory(categoriesAndItems, category);
      update.ordinal = ordinal;
    }

    updateItems([update]);
  };

  const handleAddItemAfter = (displayItem: CategoryDisplayItem, index: number) => {
    const category = displayItem.item.category;
    const categoryAndItems = categoriesAndItems.find(
      (categoryAndItems) => categoryAndItems.category === category
    );

    if (
      !category &&
      categoryAndItems &&
      index === categoryAndItems.categoryDisplayItems.length - 1
    ) {
      // Already at the end of the list, focus the blank item instead
      blankItemRef.current?.focus();

      // Scroll to reveal after the list updates
      setBlankItemNeedsScroll(true);
    } else {
      addItem({
        name: '',
        category,
        ordinal: displayItem.item.ordinal + 1,
        isChecked: false,
      });

      setIndexNeedsFocus({ category, index: index + 1 });
    }
  };

  const handleBackspaceTruncate = (displayItem: CategoryDisplayItem, index: number) => {
    deleteItems([displayItem.localId]);

    if (index > 0) {
      setIndexNeedsFocus({ category: displayItem.item.category, index: index - 1 });
    }
  };

  const handleBlankItemAdd = (name: string, category: string, refocus: boolean) => {
    const [ordinal] = getEndOfCategory(categoriesAndItems, category);

    addItem({
      name,
      category,
      ordinal,
      isChecked: false,
    });

    if (refocus) {
      blankItemRef.current?.focus();

      // Scroll to reveal after the list updates
      setBlankItemNeedsScroll(true);
    }
  };

  const handleBlankItemBackspaceTruncate = () => {
    if (!categoriesAndItems.length) {
      return;
    }
    const lastCategoryAndItems = categoriesAndItems[categoriesAndItems.length - 1];

    setIndexNeedsFocus({
      category: lastCategoryAndItems.category,
      index: lastCategoryAndItems.categoryDisplayItems.length - 1,
    });
  };

  // TODO: refactor / clean up dragging logic
  const {
    handleDragStart: handleItemDragStart,
    handleDragMove: handleItemDragMove,
    handleDragEnd: handleItemDragEnd,
  } = useItemDragHandlers({
    itemElements: itemRefs.current,
    updateItems,
    categoriesAndItems,
    dragDisplayItem,
    setDragItemLocalId,
    setDragItemToCategory,
    setDragItemToIndex,
  });

  const {
    handleDragStart: handleCategoryDragStart,
    handleDragMove: handleCategoryDragMove,
    handleDragEnd: handleCategoryDragEnd,
  } = useCategoryDragHandlers({
    categoryElements: categoryRefs.current,
    updateCategoryOrder,
    categoriesAndItems,
    dragCategory,
    setDragCategory,
    setDragCategoryToIndex,
  });

  const handleDragMove = (cursor: [number, number]) => {
    handleItemDragMove(cursor);
    handleCategoryDragMove(cursor);
  };

  const handleDragEnd = () => {
    handleItemDragEnd();
    handleCategoryDragEnd();
  };

  const isSelected = (displayItem: CategoryDisplayItem) =>
    selectedItemLocalIds.has(displayItem.localId);

  // Always include the default category to display the blank item placeholder
  const categoriesAndItemsWithDefault = [...categoriesAndItems];
  if (!categoriesAndItems.find(({ category }) => !category)) {
    categoriesAndItemsWithDefault.push({
      category: '',
      categoryDisplayItems: [],
    });
  }

  return (
    <CursorContextProvider onChange={handleDragMove}>
      <CategoriesAndItemsContainer ref={containerRef} onLostPointerCapture={handleDragEnd}>
        {categoriesAndItemsWithDefault.map(({ category, categoryDisplayItems }, categoryIndex) => (
          <CategoryContainer
            key={category}
            ref={(el) => updateCategoryRef(category, el)}
            $isCollapsed={category === dragCategory}
          >
            {!!currentCategories.length && (
              <Spacer $pt="24px">
                <CategoryTitle
                  category={category}
                  disabled={disabled}
                  onDragStart={() => handleCategoryDragStart(category, categoryIndex)}
                  dragContainerRef={containerRef}
                />
              </Spacer>
            )}
            {categoryDisplayItems.map((displayItem, index) => (
              <EditingViewItem
                key={displayItem.localId}
                ref={(el) => updateItemRef(displayItem, el)}
                dragContainerRef={containerRef}
                displayItem={displayItem}
                isSelected={isSelected(displayItem)}
                isHidden={displayItem === dragDisplayItem}
                onUpdateItem={(request) => updateItems([request])}
                onAcceptSuggestion={handleAcceptSuggestion}
                onAddItemAfter={() => handleAddItemAfter(displayItem, index)}
                onBackspaceTruncate={() => handleBackspaceTruncate(displayItem, index)}
                onDragStart={() => handleItemDragStart(category, displayItem)}
                onChangeIsSelected={setIsSelected}
                disabled={disabled}
              />
            ))}
            {!category && (
              <EditingViewBlankItem
                ref={blankItemRef}
                onAddItem={handleBlankItemAdd}
                onBackspaceTruncate={handleBlankItemBackspaceTruncate}
                disabled={disabled}
              />
            )}
          </CategoryContainer>
        ))}
        {dragDisplayItem && containerRef.current && (
          <DragContainer parent={containerRef.current} onScroll={handleDragMove}>
            <EditingViewItem
              dragContainerRef={containerRef}
              displayItem={dragDisplayItem}
              isSelected={isSelected(dragDisplayItem)}
              isHidden={false}
              onUpdateItem={() => undefined}
              onAcceptSuggestion={() => undefined}
              onAddItemAfter={() => undefined}
              onBackspaceTruncate={() => undefined}
              onDragStart={() => undefined}
              onChangeIsSelected={() => undefined}
              disabled
            />
          </DragContainer>
        )}
        {dragCategory !== undefined && containerRef.current && (
          <DragContainer parent={containerRef.current} onScroll={handleDragMove}>
            <CategoryTitle
              category={dragCategory}
              disabled
              onDragStart={() => undefined}
              dragContainerRef={containerRef}
            />
          </DragContainer>
        )}
      </CategoriesAndItemsContainer>
    </CursorContextProvider>
  );
}

export function EditingView({
  shoppingList,
  displayItems,
  addItem,
  updateItems,
  deleteItems,
  updateCategoryOrder,
  disabled,
  isActionsModalOpen,
  onCloseActionsModal,
}: {
  shoppingList: ShoppingListData;
  displayItems: ShoppingListDisplayItem[];
  addItem: (request: ShoppingListRequestAddItem) => void;
  updateItems: (updates: DisplayItemUpdate[]) => void;
  deleteItems: (itemLocalIds: string[]) => void;
  updateCategoryOrder: (categoryOrder: string[]) => void;
  disabled: boolean;
  isActionsModalOpen: boolean;
  onCloseActionsModal: () => void;
}) {
  const {
    categoriesAndItems,
    currentCategories,
    dragDisplayItem,
    dragCategory,
    setDragItemLocalId,
    setDragItemToCategory,
    setDragItemToIndex,
    setDragCategory,
    setDragCategoryToIndex,
  } = useCategorizedItems(shoppingList, displayItems);

  const {
    selectedItemLocalIds,
    setIsSelected,
    isEditingCategory,
    setIsEditingCategory,
    handleClearSelection,
    handleChangeSelectionCategory,
    handleDeleteSelection,
    commonSelectionCategory,
  } = useItemSelectionState({ categoriesAndItems, updateItems, deleteItems });

  const handleClickSelectAll = () => {
    for (const { localId } of displayItems) {
      setIsSelected(localId, true);
    }

    onCloseActionsModal();
  };

  return (
    <>
      <Spacer $pb={selectedItemLocalIds.size > 0 ? '72px' : '24px'}>
        <CategoriesAndItems
          addItem={addItem}
          updateItems={updateItems}
          deleteItems={deleteItems}
          updateCategoryOrder={updateCategoryOrder}
          selectedItemLocalIds={selectedItemLocalIds}
          setIsSelected={setIsSelected}
          categoriesAndItems={categoriesAndItems}
          currentCategories={currentCategories}
          dragDisplayItem={dragDisplayItem}
          dragCategory={dragCategory}
          setDragItemLocalId={setDragItemLocalId}
          setDragItemToCategory={setDragItemToCategory}
          setDragItemToIndex={setDragItemToIndex}
          setDragCategory={setDragCategory}
          setDragCategoryToIndex={setDragCategoryToIndex}
          disabled={disabled}
        />
      </Spacer>
      {selectedItemLocalIds.size > 0 && (
        <SelectedActions
          count={selectedItemLocalIds.size}
          onClearSelection={handleClearSelection}
          onClickChangeCategory={() => setIsEditingCategory(true)}
          onClickDelete={handleDeleteSelection}
          disabled={disabled}
        />
      )}
      <CategorySelectModal
        isOpen={isEditingCategory}
        initialCategory={commonSelectionCategory}
        currentCategories={currentCategories}
        onSelect={handleChangeSelectionCategory}
        onCancel={() => setIsEditingCategory(false)}
      />
      <ShoppingListActionsModal
        isOpen={isActionsModalOpen}
        onCancel={onCloseActionsModal}
        onClickSelectAll={handleClickSelectAll}
        disabled={disabled}
      />
    </>
  );
}
