import styled from 'styled-components';
import { NakedButton } from './common';
import { useNavigate } from './Router';
import { useSession } from 'src/hooks/useSession';
import { CurrentUser } from 'common/apiTypes';
import { UserPicture } from './UserPicture';
import { useState } from 'react';
import { AccountManagementModal } from './AccountManagementModal';

const NavBarContainer = styled.div`
  position: relative;
  min-height: 66px;
`;

const NavBarContents = styled.div`
  padding: 18px 64px;
  text-align: center;
`;

const BreakoutContainer = styled.div`
  padding: 24px;
  text-align: center;
`;

const StyledButton = styled(NakedButton)`
  position: absolute;
  left: 12px;
  top: 12px;
  padding: 12px 16px;
  height: 42px;
`;

const AccountMenuButtonContainer = styled.div`
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 12px;
`;

function BackButton() {
  const { backOrDefault } = useNavigate();

  const handleClick = () => {
    backOrDefault('/');
  };

  return <StyledButton onClick={handleClick}>&#10094;</StyledButton>;
}

function AccountMenuButton({
  currentUser,
  onRefreshCurrentUser,
  onLogOut,
}: {
  currentUser: CurrentUser;
  onRefreshCurrentUser: () => Promise<void>;
  onLogOut: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AccountMenuButtonContainer>
        <UserPicture src={currentUser.pictureUrl} onClick={() => setIsOpen(true)} />
      </AccountMenuButtonContainer>
      <AccountManagementModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        currentUser={currentUser}
        onRefreshCurrentUser={onRefreshCurrentUser}
        onLogOut={onLogOut}
      />
    </>
  );
}

export function PageHeading({
  backButton,
  breakoutContent,
  children,
}: {
  backButton?: boolean;
  breakoutContent?: boolean;
  children?: React.ReactNode;
}) {
  const { navigate } = useNavigate();
  const { sessionToken, currentUser, onRefreshCurrentUser, onDestroySession } = useSession();

  const handleRefreshCurrentUser = () => {
    if (!sessionToken) {
      throw new Error('no active session');
    }
    return onRefreshCurrentUser(sessionToken);
  };

  const handleLogOut = () => {
    // TODO: delete the session on the backend
    onDestroySession();
    navigate('/');
  };

  return (
    <>
      <NavBarContainer>
        {backButton && <BackButton />}
        <NavBarContents>{!breakoutContent && children}</NavBarContents>
        {currentUser && (
          <AccountMenuButton
            currentUser={currentUser}
            onRefreshCurrentUser={handleRefreshCurrentUser}
            onLogOut={handleLogOut}
          />
        )}
      </NavBarContainer>
      {breakoutContent && <BreakoutContainer>{children}</BreakoutContainer>}
    </>
  );
}
