import { GroupInviteDetailsResponse, OtherUser } from 'common/apiTypes';
import { useEffect, useState } from 'react';
import { ErrorPage } from '../ErrorPage';
import { useSharingClient } from 'src/api/sharing';
import { logError } from 'src/components/logging';
import { PageHeading } from 'src/components/PageHeading';
import { ErrorText, HeadingText, Spacer, Text } from 'src/components/common';
import { LoadingPage } from '../LoadingPage';
import { getUserDisplayName } from 'src/components/AccountManagementModal';
import { UserPicture } from 'src/components/UserPicture';
import styled from 'styled-components';
import { useSession } from 'src/hooks/useSession';
import { Spinner } from 'src/components/Spinner';
import { Link } from 'src/components/Router';
import { AppHelmet } from 'src/components/AppHelmet';

const PageContainer = styled.div`
  max-width: 440px;
  margin: auto;
`;

function InviteDetails({ groupOwner }: { groupOwner: OtherUser }) {
  const { currentUser } = useSession();

  const ownerDisplayName = getUserDisplayName(groupOwner);

  const hasCurrentGroup = !!currentUser?.otherGroupUsers?.length;
  const isCurrentGroupOwner = currentUser?.isGroupOwner;

  return (
    <>
      <Text>You&rsquo;re invited to join {ownerDisplayName}&rsquo;s group!</Text>
      <Spacer $mv="24px">
        <UserPicture src={groupOwner.pictureUrl} />
      </Spacer>
      <Text $justify>
        By accepting, you&rsquo;ll gain access to group members&rsquo; lists, and your lists will be
        shared with the group.
      </Text>
      {hasCurrentGroup && (
        <Spacer $mt="24px">
          <ErrorText>
            Warning: this action will{' '}
            {isCurrentGroupOwner
              ? 'disband your current group'
              : 'remove you from your current group'}
            .
          </ErrorText>
        </Spacer>
      )}
    </>
  );
}

export function JoinGroupPage({ inviteToken }: { inviteToken: string }) {
  const { getGroupInviteDetails, postAcceptGroupInvite } = useSharingClient();

  const [inviteDetails, setInviteDetails] = useState<GroupInviteDetailsResponse>();
  const [inviteDetailsError, setInviteDetailsError] = useState('');

  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [joinError, setJoinError] = useState('');
  const [joinSuccess, setJoinSuccess] = useState(false);

  // TODO: come up with a pattern to load this in parallel with the AuthRouter / session,
  // instead of back-to-back loading screens
  useEffect(() => {
    const doAsync = async () => {
      try {
        const response = await getGroupInviteDetails({ inviteToken });
        setInviteDetails(response);
      } catch (err) {
        logError(err);
        setInviteDetailsError('Something went wrong');
      }
    };
    void doAsync();
  }, [inviteToken, getGroupInviteDetails]);

  // TODO: invite may be revoked or expired, handle this more gracefully
  if (inviteDetailsError) {
    return <ErrorPage error={inviteDetailsError} />;
  }

  if (!inviteDetails) {
    return <LoadingPage />;
  }

  const handleJoinGroup = async () => {
    try {
      setIsJoinLoading(true);
      setJoinError('');

      await postAcceptGroupInvite({ inviteToken });

      setJoinSuccess(true);
    } catch (err) {
      logError(err);
      setJoinError('Something went wrong');
    } finally {
      setIsJoinLoading(false);
    }
  };

  return (
    <>
      <AppHelmet context="Join Group" />
      <PageHeading backButton>
        <HeadingText>Join Group</HeadingText>
      </PageHeading>
      <PageContainer>
        {joinSuccess ? (
          <Text>
            Success! <Link href="/">Return home</Link>
          </Text>
        ) : (
          <>
            <InviteDetails groupOwner={inviteDetails.groupOwner} />
            <Spacer $mt="24px">
              {isJoinLoading ? (
                <Spinner />
              ) : (
                <button onClick={handleJoinGroup}>Accept invite</button>
              )}
            </Spacer>
            {joinError && (
              <Spacer $mt="12px">
                <ErrorText>{joinError}</ErrorText>
              </Spacer>
            )}
          </>
        )}
      </PageContainer>
    </>
  );
}
