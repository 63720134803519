import {
  CreateShoppingListRequest,
  DeleteShoppingListRequest,
  GetShoppingListsResponse,
  RestoreShoppingListRequest,
  ShoppingListData,
  UpdateShoppingListOrderRequest,
  UpdateShoppingListRequest,
} from 'common/apiTypes';
import { useRef } from 'react';
import { useCurrentRef } from 'src/hooks/useCurrentRef';
import { useSession } from 'src/hooks/useSession';
import { getAuthHeaders } from './auth';

export function optimisticShoppingListUpdate(
  current: ShoppingListData,
  request: Omit<UpdateShoppingListRequest, 'shoppingListId'>
): ShoppingListData {
  // Note: changes to archived/trashed status are not optimistically updated
  return {
    ...current,
    name: request.name ?? current.name,
    categoryOrder: request.categoryOrder ?? current.categoryOrder,
    isPinned: request.isPinned ?? current.isPinned,
    isDraft: request.isDraft ?? current.isDraft,
  };
}

export function useShoppingListClient() {
  const { sessionToken } = useSession();

  async function getShoppingLists() {
    const response = await fetch('/api/shoppingLists', {
      headers: getAuthHeaders(sessionToken),
    });

    return (await response.json()) as GetShoppingListsResponse;
  }

  async function postUpdateShoppingListOrder(request: UpdateShoppingListOrderRequest) {
    const response = await fetch('/api/updateShoppingListOrder', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as {};
  }

  async function getShoppingList(listId: string) {
    const response = await fetch(`/api/shoppingList/${listId}`, {
      headers: getAuthHeaders(sessionToken),
    });

    return (await response.json()) as ShoppingListData;
  }

  async function postCreateShoppingList(request: CreateShoppingListRequest) {
    const response = await fetch('/api/createShoppingList', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as ShoppingListData;
  }

  async function postUpdateShoppingList(request: UpdateShoppingListRequest) {
    const response = await fetch('/api/updateShoppingList', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as ShoppingListData;
  }

  async function postDeleteShoppingList(request: DeleteShoppingListRequest) {
    const response = await fetch('/api/deleteShoppingList', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as {};
  }

  async function postRestoreShoppingList(request: RestoreShoppingListRequest) {
    const response = await fetch('/api/restoreShoppingList', {
      method: 'POST',
      headers: {
        ...getAuthHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    return (await response.json()) as ShoppingListData;
  }

  const currentRef = useCurrentRef({
    getShoppingLists,
    postUpdateShoppingListOrder,
    getShoppingList,
    postCreateShoppingList,
    postUpdateShoppingList,
    postDeleteShoppingList,
    postRestoreShoppingList,
  });

  const staticRef = useRef({
    getShoppingLists: () => currentRef.current.getShoppingLists(),
    postUpdateShoppingListOrder: (request: UpdateShoppingListOrderRequest) =>
      currentRef.current.postUpdateShoppingListOrder(request),
    getShoppingList: (listId: string) => currentRef.current.getShoppingList(listId),
    postCreateShoppingList: (request: CreateShoppingListRequest) =>
      currentRef.current.postCreateShoppingList(request),
    postUpdateShoppingList: (request: UpdateShoppingListRequest) =>
      currentRef.current.postUpdateShoppingList(request),
    postDeleteShoppingList: (request: DeleteShoppingListRequest) =>
      currentRef.current.postDeleteShoppingList(request),
    postRestoreShoppingList: (request: RestoreShoppingListRequest) =>
      currentRef.current.postRestoreShoppingList(request),
  });

  return staticRef.current;
}
