import { AppHelmet } from 'src/components/AppHelmet';
import { Spinner } from 'src/components/Spinner';

export function LoadingPage() {
  return (
    <>
      <AppHelmet context="Loading" />
      <Spinner centered />
    </>
  );
}
