import styled from 'styled-components';

const StyledImg = styled.img<{ onClick?: (() => void) | undefined }>`
  border: 1px solid black;
  border-radius: 50%;
  ${(p) =>
    !!p.onClick &&
    `
  cursor: pointer;
  &:hover {
    border-color: #aaa;
  }
  `}
`;

export function UserPicture({
  src,
  onClick,
  size = 'sm',
}: {
  src: string | undefined;
  onClick?: () => void;
  size?: 'xs' | 'sm' | 'lg';
}) {
  if (!src) {
    // TODO: show a generic picture
    return <></>;
  }

  return (
    <StyledImg
      src={src}
      height={size === 'lg' ? '96px' : size === 'xs' ? '24px' : '42px'}
      onClick={onClick}
    />
  );
}
