import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { ItemSuggestion } from 'common/apiTypes';
import { FlexChild, Text } from 'src/components/common';

const SuggestionContainer = styled.div<{ $isFlipped: boolean; $isHidden: boolean }>`
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  border: 1px solid gray;
  background: white;

  display: flex;

  ${(p) =>
    p.$isFlipped
      ? `
  flex-direction: column-reverse;
  bottom: 100%;
  box-shadow: -2px -2px 5px #bbb;
  `
      : `
  flex-direction: column;
  top: 100%;
  box-shadow: -2px 2px 5px #bbb;
  `}

  ${(p) => p.$isHidden && 'visibility: hidden;'}
`;

const SuggestionItem = styled.div`
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 12px;

  &:hover {
    background: #eee;
  }
`;

export function ItemSuggestionList({
  anchorRef,
  suggestions,
  onAcceptSuggestion,
}: {
  anchorRef: React.RefObject<HTMLDivElement>;
  suggestions: ItemSuggestion[];
  onAcceptSuggestion: (suggestion: ItemSuggestion) => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isFlipped, setIsFlipped] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const anchorEl = anchorRef.current;
    if (!anchorEl) {
      return;
    }

    const updateFlipped = () => {
      const anchorRect = anchorEl.getBoundingClientRect();

      const visualOffsetTop = window.visualViewport?.offsetTop || 0;
      const visualHeight = window.visualViewport?.height || window.innerHeight;

      const topSpace = anchorRect.top - visualOffsetTop;
      const bottomSpace = visualHeight - (anchorRect.bottom - visualOffsetTop);
      setIsFlipped(topSpace > bottomSpace);
      setIsInitialized(true);
    };

    updateFlipped();

    // IntersectionObserver is not reliable on mobile, so also listen for visual viewport changes
    const visualViewport = window.visualViewport;
    if (visualViewport) {
      visualViewport.addEventListener('resize', updateFlipped);
      visualViewport.addEventListener('scroll', updateFlipped);
    }

    // Trigger whenever the list container is not fully visible
    const observer = new IntersectionObserver(updateFlipped, { threshold: 1.0 });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (visualViewport) {
        visualViewport.removeEventListener('resize', updateFlipped);
        visualViewport.removeEventListener('scroll', updateFlipped);
      }

      observer.disconnect();
    };
  }, [suggestions.length, anchorRef]);

  const handleMouseDown = (e: React.MouseEvent) => {
    // Prevent moving focus away from text input
    e.preventDefault();
  };

  return (
    <SuggestionContainer ref={containerRef} $isFlipped={isFlipped} $isHidden={!isInitialized}>
      {suggestions.map((suggestion) => (
        <SuggestionItem
          key={suggestion.name}
          tabIndex={-1}
          onMouseDown={handleMouseDown}
          onClick={() => onAcceptSuggestion(suggestion)}
        >
          <FlexChild>
            <Text>{suggestion.name}</Text>
          </FlexChild>
          <FlexChild $shrink>
            <Text $muted $size="sm" $noWrap>
              {suggestion.category || 'No Category'}
            </Text>
          </FlexChild>
        </SuggestionItem>
      ))}
    </SuggestionContainer>
  );
}
