import { useMemo, useState } from 'react';
import { ShoppingListDisplayItem } from './useShoppingListItems';
import { ShoppingListData } from 'common/apiTypes';

export interface CategoryDisplayItem extends ShoppingListDisplayItem {
  index: number;
}

export interface CategoryWithItems {
  category: string;
  categoryDisplayItems: CategoryDisplayItem[];
}

function getSortedCategories(
  listCategoryOrder: string[],
  allCategories: string[],
  dragCategory: string | undefined,
  dragCategoryToIndex: number
): string[] {
  const categorySet = new Set(allCategories);
  const orderedCategories = [];
  for (const category of listCategoryOrder) {
    if (category && categorySet.has(category)) {
      categorySet.delete(category);
      orderedCategories.push(category);
    }
  }

  // Categories with no specified order are sorted
  const remainingCategories = Array.from(categorySet.values()).filter(Boolean);
  remainingCategories.sort();

  // Empty category is always last
  if (categorySet.has('')) {
    remainingCategories.push('');
  }

  const sortedCategories = [...orderedCategories, ...remainingCategories].filter(
    (category) => category !== dragCategory
  );

  if (dragCategory !== undefined) {
    sortedCategories.splice(dragCategoryToIndex, 0, dragCategory);
  }

  return sortedCategories;
}

export function useCategorizedItems(
  shoppingList: ShoppingListData,
  displayItems: ShoppingListDisplayItem[]
) {
  const [dragItemLocalId, setDragItemLocalId] = useState<string>();
  const [dragItemToCategory, setDragItemToCategory] = useState('');
  const [dragItemToIndex, setDragItemToIndex] = useState(0);

  const [dragCategory, setDragCategory] = useState<string>();
  const [dragCategoryToIndex, setDragCategoryToIndex] = useState(0);

  const categoriesAndItems: CategoryWithItems[] = useMemo(() => {
    const itemsByCategory = new Map<string, ShoppingListDisplayItem[]>();

    const getCategoryItems = (category: string) => {
      let categoryItems = itemsByCategory.get(category);
      if (categoryItems) {
        return categoryItems;
      }

      categoryItems = [];
      itemsByCategory.set(category, categoryItems);
      return categoryItems;
    };

    let dragDisplayItem;
    for (const displayItem of displayItems) {
      if (dragItemLocalId && displayItem.localId === dragItemLocalId) {
        dragDisplayItem = displayItem;
        continue;
      }

      getCategoryItems(displayItem.item.category).push(displayItem);
    }

    itemsByCategory.forEach((categoryItems) =>
      categoryItems.sort((a, b) => a.item.ordinal - b.item.ordinal)
    );

    if (dragDisplayItem) {
      getCategoryItems(dragItemToCategory).splice(dragItemToIndex, 0, dragDisplayItem);
    }

    const sortedCategories = getSortedCategories(
      shoppingList.categoryOrder,
      Array.from(itemsByCategory.keys()),
      dragCategory,
      dragCategoryToIndex
    );

    return sortedCategories
      .map((category) => ({
        category,
        categoryDisplayItems: (itemsByCategory.get(category) || []).map((displayItem, index) => ({
          ...displayItem,
          index,
        })),
      }))
      .filter(({ categoryDisplayItems }) => categoryDisplayItems.length);
  }, [
    shoppingList.categoryOrder,
    displayItems,
    dragItemLocalId,
    dragItemToCategory,
    dragItemToIndex,
    dragCategory,
    dragCategoryToIndex,
  ]);

  const currentCategories: string[] = useMemo(
    () => categoriesAndItems.map(({ category }) => category).filter(Boolean),
    [categoriesAndItems]
  );

  let dragDisplayItem;
  if (dragItemLocalId) {
    for (const { categoryDisplayItems } of categoriesAndItems) {
      for (const displayItem of categoryDisplayItems) {
        if (displayItem.localId === dragItemLocalId) {
          dragDisplayItem = displayItem;
          break;
        }
      }
    }
  }

  return {
    categoriesAndItems,
    currentCategories,
    dragDisplayItem,
    dragCategory,
    setDragItemLocalId,
    setDragItemToCategory,
    setDragItemToIndex,
    setDragCategory,
    setDragCategoryToIndex,
  };
}
