import { CurrentUser, OtherUser } from 'common/apiTypes';
import { Modal } from './Modal';
import { ErrorText, FlexChild, NakedButton, Spacer, Text } from './common';
import { UserPicture } from './UserPicture';
import styled from 'styled-components';
import { logError } from './logging';
import { useRef, useState } from 'react';
import { useSharingClient } from 'src/api/sharing';
import { Spinner } from './Spinner';
import { Link } from './Router';

const OtherUserRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const GroupViewContainer = styled.div`
  width: 320px;
  max-width: 100%;
  padding: 0 24px;
`;

const InviteLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledInput = styled.input`
  width: 100%;
`;

const StyledButton = styled(NakedButton)`
  padding: 0;
  font-size: 24px;
`;

export function getUserDisplayName(user: CurrentUser | OtherUser) {
  return user.firstName || user.email || 'Anonymous User';
}

function GroupInviteLink({ inviteToken }: { inviteToken: string }) {
  const url = `${window.location.origin}/join/group/${inviteToken}`;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopyUrl = () => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.select();
    void navigator.clipboard.writeText(inputRef.current.value);
  };

  return (
    <InviteLinkContainer>
      <FlexChild>
        <Text>Invite:</Text>
      </FlexChild>
      <FlexChild $grow $shrink>
        <StyledInput ref={inputRef} type="text" value={url} onClick={handleCopyUrl} readOnly />
      </FlexChild>
      <StyledButton onClick={handleCopyUrl}>&#x2398;</StyledButton>
    </InviteLinkContainer>
  );
}

function GroupOwnerView({
  currentUser,
  onRefreshCurrentUser,
}: {
  currentUser: CurrentUser;
  onRefreshCurrentUser: () => Promise<void>;
}) {
  const { postCreateGroupInvite } = useSharingClient();

  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const [error, setError] = useState('');

  const handleCreateInvite = async () => {
    try {
      setIsInviteLoading(true);
      await postCreateGroupInvite();
      await onRefreshCurrentUser();
    } catch (err) {
      logError(err);
      setError('Something went wrong');
    } finally {
      setIsInviteLoading(false);
    }
  };

  const handleRemoveMember = () => {
    // TODO
    alert('not implemented');
  };

  const handleDeleteGroup = () => {
    // TODO
    alert('not implemented');
  };

  return (
    <GroupViewContainer>
      {error && (
        <Spacer $mt="24px">
          <ErrorText>{error}</ErrorText>
        </Spacer>
      )}
      <Spacer $mt="24px">
        {isInviteLoading ? (
          <Spinner />
        ) : currentUser.groupInviteToken ? (
          <GroupInviteLink inviteToken={currentUser.groupInviteToken} />
        ) : (
          <button onClick={handleCreateInvite}>Start new group</button>
        )}
      </Spacer>
      <Spacer $mt="24px">
        {currentUser.otherGroupUsers.map((otherUser) => (
          <OtherUserRow key={otherUser.userId}>
            <UserPicture size="sm" src={otherUser.pictureUrl} />
            <FlexChild $grow $shrink>
              <Text $justify>{getUserDisplayName(otherUser)}</Text>
            </FlexChild>
            <button onClick={handleRemoveMember}>Remove</button>
          </OtherUserRow>
        ))}
      </Spacer>
      {!!(currentUser.groupInviteToken || currentUser.otherGroupUsers.length) && (
        <Spacer $mt="24px">
          <button onClick={handleDeleteGroup}>Disband group</button>
        </Spacer>
      )}
    </GroupViewContainer>
  );
}

function GroupMemberView({
  currentUser,
  onRefreshCurrentUser,
}: {
  currentUser: CurrentUser;
  onRefreshCurrentUser: () => Promise<void>;
}) {
  const handleLeaveGroup = () => {
    // TODO
    void onRefreshCurrentUser;
    alert('not implemented');
  };

  return (
    <GroupViewContainer>
      <Spacer $mt="24px">
        {currentUser.otherGroupUsers.map((otherUser) => (
          <OtherUserRow key={otherUser.userId}>
            <UserPicture size="sm" src={otherUser.pictureUrl} />
            <FlexChild $grow $shrink>
              <Text $justify>
                {getUserDisplayName(otherUser)}
                {otherUser.isGroupOwner && ' (group owner)'}
              </Text>
            </FlexChild>
          </OtherUserRow>
        ))}
      </Spacer>
      <Spacer $mt="24px">
        <button onClick={handleLeaveGroup}>Leave group</button>
      </Spacer>
    </GroupViewContainer>
  );
}

export function AccountManagementModal({
  isOpen,
  onCancel,
  currentUser,
  onRefreshCurrentUser,
  onLogOut,
}: {
  isOpen: boolean;
  onCancel: () => void;
  currentUser: CurrentUser;
  onRefreshCurrentUser: () => Promise<void>;
  onLogOut: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onCancel={onCancel}>
      <Text $size="lg">Logged in as {getUserDisplayName(currentUser)}</Text>
      <Spacer $mt="24px">
        <UserPicture size="lg" src={currentUser.pictureUrl} />
      </Spacer>
      <Spacer $mt="24px">
        <button onClick={onLogOut}>Log out</button>
      </Spacer>
      <Spacer $mv="24px">
        <hr />
      </Spacer>
      <Text $size="lg">My Sharing Group</Text>
      <Spacer $mt="12px">
        <Text $size="sm" $muted>
          All lists are shared within this group
        </Text>
      </Spacer>
      {currentUser.isGroupOwner ? (
        <GroupOwnerView currentUser={currentUser} onRefreshCurrentUser={onRefreshCurrentUser} />
      ) : (
        <GroupMemberView currentUser={currentUser} onRefreshCurrentUser={onRefreshCurrentUser} />
      )}
      <Spacer $mt="24px">
        <Text $size="sm" $muted>
          <Link href="/delete-account">Delete my Account</Link>
        </Text>
      </Spacer>
    </Modal>
  );
}
